import { useEffect } from "react"
import { useFunctions, useQuery } from "../composables"

export const ReceivePage = () => {
  const q = useQuery()
  const { setToken } = useFunctions()

  useEffect(() => {
    const code  = q.get('code')
    const scope = q.get('scope')
    const state = q.get('state')

    if (code) {
      setToken({code, scope, state}).then(_res => {
        window.close()
      }).catch(err => {
        console.error(err)
        window.close()
      })
    }
    else {
      console.error('no code')
    }
  }, [])

  return (
    <>
      <h1 className="text-slate-400">Loading ...</h1>
    </>
  )
}
