import { useState, useEffect, Fragment, useContext, useRef } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { DocumentTextIcon, FolderIcon } from "@heroicons/react/24/outline"
import { drive_v3 } from "googleapis"
import { classNames } from "../../lib"
import { getService, getServiceRole, tokenConverter } from "../../types"
import { useFunctions } from "../../composables"
import { ProgressContext, UserContext } from "../../contexts"
import { SyncTargetEditorProps } from "."
import { updateToken } from "../../lib/updateToken"

export const SyncTargetGoogleDriveFile = ({type, target, onChange} : SyncTargetEditorProps) => {
  if (target.serviceId !== 'google-drive-file') return null
  const { user } = useContext(UserContext)
  if (!user) return null

  const [filterWord, setFilterWord] = useState<string>('')

  const { getGoogleDriveFiles, generateAuthUrl } = useFunctions()
  const [files, setFiles] = useState<drive_v3.Schema$File[]>([])

  const permissions = getService('google-drive-file')?.roles || [] // Get predefined permissions

  const { showProgress } = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  const getFiles = async (tokenId: string) => {
    progress.current = showProgress('Loading...')

    try {
      const res = await getGoogleDriveFiles({tokenId})
      if (res.data.status != 200 || !res.data.files) {
        console.error(res.data, tokenId)
        if (res.data.data && res.data.data.error === 'invalid_grant') {
          updateToken(user, tokenId)
        }
        else {
          alert(res.data.data?.description || res.data.statusText || 'Failed to load files.')
        }
        progress.current?.()
        return
      }
      setFiles(res.data.files)
      progress.current?.()
    }
    catch (err) {
      console.error(err)
      alert(err)
      progress.current?.()
    }
  }

  useEffect(() => {
    if (!target.tokenId) return
    getFiles(target.tokenId).catch(err => {
      alert(err)
      progress.current?.()
    })

    return
  }, [target.tokenId])

  const service = getService('google-drive-file')
  if (!service) return null
  useEffect(() => {
    if (!target.defaultRoleId) onChange({...target, defaultRoleId: service.roles[0]?.id})
  }, [target.defaultRoleId])

  return (
    <>
      <div className="space-y-1">
        <div>
          <label htmlFor="" className="block text-sm font-medium text-gray-500">File</label>
        </div>
        <div className="">
          <Listbox
            value={target.file}
            onChange={file => onChange({...target, file: file ? file : null})}
            disabled={!target.tokenId}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="msx-select w-full">
                    { target.file ?
                      <div className="flex items-center gap-3">
                        <span className="font-normal block truncate">
                          { target.file.name }
                        </span>
                        <span className="font-normal block truncate font-light text-gray-400">
                          { target.file.description }
                        </span>
                      </div>
                      :
                      <span className="inline-block text-gray-400">
                        Select file ...
                      </span>
                    }
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                      { 0 < files.length ? (
                        <div className="py-2 px-3">
                          <input
                            type="text"
                            className="msx-input"
                            placeholder="Search file ..."
                            defaultValue={filterWord}
                            onChange={ev => setFilterWord(ev.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="py-2 px-3">
                          <span className="block text-gray-400">
                            No file is found. Go to your Google Drive and create your first file.
                          </span>
                        </div>
                      ) }

                      { files
                      .filter(file => file.name?.toLowerCase().includes(filterWord.toLowerCase()))
                      .map(file => (
                        <Listbox.Option
                          key={file.id}
                          className={({ selected }) =>
                            classNames(
                              "msx-dropdown-menu-item",
                              selected || target.file?.id == file.id
                                ? "msx-dropdown-menu-item-selected"
                                : ""
                            )
                          }
                          value={file}
                        >
                          <div className="flex flex-col text-left gap-1">
                            <div className="inline-flex items-center gap-2">
                              {file.mimeType === "application/vnd.google-apps.folder" ? (
                                <FolderIcon className="w-5 h-5 shrink-0" />
                              ) : (
                                <DocumentTextIcon className="w-5 h-5 shrink-0" />
                              )}
                              <span
                                className={classNames(
                                  target.file?.id == file.id
                                    ? "font-semibold"
                                    : "font-normal",
                                  "block truncate"
                                )}
                              >
                                { file.name }
                              </span>
                            </div>
                            <div className="inline-flex items-center gap-2 pl-7">
                              <span
                                className={classNames(
                                  target.file?.id == file.id
                                    ? "font-normal"
                                    : "font-light",
                                  "block truncate font-light text-gray-400"
                                )}
                              >
                                { /* File path */ }
                              </span>
                            </div>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      {type == 'dst' && (
        <>
          <div className="space-y-1">
            <div>
              <label htmlFor="" className="block text-sm font-medium text-gray-500">Default permission when added</label>
            </div>
            <div>
              <Listbox
                value={target.defaultRoleId}
                onChange={value => onChange({...target, defaultRoleId: value})} // Update the selected permission state when a new permission is selected
                disabled={!target.tokenId}
              >
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button className="msx-select w-full">
                        {target.defaultRoleId ? (
                          <span className="block truncate">
                            { getServiceRole(target.serviceId, target.defaultRoleId)?.description || target.defaultRoleId }
                          </span>
                        ) : (
                          <span className="block text-gray-400 truncate">
                            Select default permission ...
                          </span>
                        )}
                      </Listbox.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-px w-full msx-dropdown-menu">
                          {permissions.map(permission => (
                            <Listbox.Option
                              key={permission.id}
                              className={({ selected }) =>
                                classNames(
                                  "msx-dropdown-menu-item",
                                  selected ? "msx-dropdown-menu-item-selected" : ""
                                )
                              }
                              value={permission.id}
                            >
                              {permission.description}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </>
      )}
    </>
  )
}
