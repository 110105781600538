import { Fragment, useState, forwardRef, useImperativeHandle } from 'react'
import { Link } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import {
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
  ArrowPathIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline"

import logo from "../img/logo.svg"

import { classNames } from "../lib"

type MenuItem = {
  label  : string;
  href   : string;
  icon   : (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

const navigation : MenuItem[] = [
  { label: "Sync settings", href: "/", icon: ArrowPathIcon },
  { label: "Activity logs", href: "/log", icon: ClipboardDocumentListIcon },
  { label: "My account", href: "/account", icon: Cog8ToothIcon },
]

const secondaryNavigation : MenuItem[] = [
  { label: "Help", href: "#", icon: QuestionMarkCircleIcon },
]

export interface MainMenuControl {
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

type MainMenuProps = {
  current: string;
}

export const MainMenu = forwardRef<MainMenuControl, MainMenuProps>((props, ref) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useImperativeHandle(ref, () => {
    return { setSidebarOpen }
  })

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-1 right-0 -mr-14 p-1">
                  <button
                    type="button"
                    className="h-12 w-12 rounded-full flex items-center justify-center hover:bg-gray-600 focus:bg-gray-600"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 px-4 flex items-center">
                <img className="h-6 w-auto" src={logo} alt="MemberSync" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="h-full flex flex-col">
                  <div className="space-y-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.label}
                        to={item.href}
                        className={classNames(
                          item.href === props.current
                            ? "bg-indigo-50 border-indigo-600 text-indigo-600"
                            : "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group border-l-4 py-2 px-3 flex items-center text-base font-medium hover:no-underline"
                        )}
                        aria-current={item.href === props.current ? "page" : undefined}
                      >
                        <item.icon
                          className={classNames(
                            item.href === props.current
                              ? "text-indigo-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.label}
                      </Link>
                    ))}
                  </div>
                  <div className="mt-auto pt-10 space-y-1">
                    {secondaryNavigation.map((item) => (
                      <Link
                        key={item.label}
                        to={item.href}
                        className="group border-l-4 border-transparent py-2 px-3 flex items-center text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        {item.label}
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-60 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <nav className="bg-gray-50 border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
          <div className="flex-shrink-0 px-4 flex items-center">
            <img className="h-6 w-auto" src={logo} alt="MemberSync" />
          </div>
          <div className="flex-grow mt-5">
            <div className="space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.label}
                  to={item.href}
                  className={classNames(
                    item.href === props.current
                      ? "bg-indigo-50 border-indigo-600 text-indigo-600"
                      : "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50",
                    "group border-l-4 py-2 px-3 flex items-center text-sm font-medium hover:no-underline"
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.href === props.current
                        ? "text-indigo-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex-shrink-0 block w-full">
            {secondaryNavigation.map((item) => (
              <Link
                key={item.label}
                to={item.href}
                className="group border-l-4 border-transparent py-2 px-3 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
              >
                <item.icon
                  className="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                  aria-hidden="true"
                />
                {item.label}
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </>
  )
})
