import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, QueryDocumentSnapshot, QuerySnapshot, startAfter, where } from "firebase/firestore"
import { useContext, useEffect, useRef, useState } from "react"
import {
  Bars3Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/24/outline"

import { MainMenu, MainMenuControl, UserMenu } from "../components"
import { classNames } from '../lib'
import { getService, Log, logConverter, SyncSetting, syncSettingConverter } from "../types"
import { useFirestore, useQuery } from "../composables"
import { UserContext } from "../contexts"
import { Link, useNavigate } from "react-router-dom"

type Activity = {
  log    : Log;
  setting: SyncSetting;
}

export const ActivityLogPage = () => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const q = useQuery()

  if (!user) {
    navigate('/')
    navigate(0)
    return (<></>)
  }

  const {firestore} = useFirestore()

  const [activities, setActivities] = useState<Activity[]>([])
  const [settings, setSettings] = useState<{[key: string]: SyncSetting | undefined}>({})
  // const [pageLimit, setPageLimit] = useState(20)
  const pageLimit = 20
  const [lastSnap, setLastSnap] = useState<QueryDocumentSnapshot<Log> | undefined>()

  useEffect(() => {
    const ref = q.get('setting')
    ? query(
      collection(firestore, `/Users/${user.uid}/Logs`).withConverter(logConverter),
      where('level', 'in', ['info', 'error']),
      where('settingId', '==', q.get('setting')),
      orderBy('timestamp', 'desc'),
      limit(pageLimit)
    )
    : query(
      collection(firestore, `/Users/${user.uid}/Logs`).withConverter(logConverter),
      where('level', 'in', ['info', 'error']),
      orderBy('timestamp', 'desc'),
      limit(pageLimit)
    )

    return onSnapshot(ref, async (snapshot: QuerySnapshot<Log>) => {
      snapshot.docs.forEach(d => {
        if (!settings[d.data().settingId])
          settings[d.data().settingId] = undefined
      })
      // const changes = snapshot.docChanges().filter(c => c.type === 'added')
      // changes.forEach(c => settings[c.doc.data().settingId] = undefined)

      for (const settingId of Object.keys(settings)) {
        if (settings[settingId]) continue

        try {
          const setting = await getDoc(
            doc(firestore, `/Users/${user.uid}/SyncSettings/${settingId}`)
            .withConverter(syncSettingConverter)
          )
          settings[settingId] = setting.data()
        }
        catch (err) {
          console.error(err)
        }
      }

      const logs = snapshot.docs
      .map(d => d.data())
      .map(log => ({log, setting: settings[log.settingId]} as Activity))
      // const logs = changes
      // .map(c => c.doc.data())
      // .map(log => ({log, setting: settings[log.settingId]} as Activity))

      setActivities(logs)
      setSettings(settings)
      setLastSnap(
        0 < snapshot.docs.length
        ? snapshot.docs[snapshot.docs.length - 1]
        : undefined
      )
    })
  }, [])

  const loadMore = () => {
    if (!lastSnap) return
    const ref = q.get('setting')
    ? query(
      collection(firestore, `/Users/${user.uid}/Logs`).withConverter(logConverter),
      where('level', 'in', ['info', 'error']),
      where('settingId', '==', q.get('setting')),
      orderBy('timestamp', 'desc'),
      startAfter(lastSnap),
      limit(pageLimit)
    )
    : query(
      collection(firestore, `/Users/${user.uid}/Logs`).withConverter(logConverter),
      where('level', 'in', ['info', 'error']),
      orderBy('timestamp', 'desc'),
      startAfter(lastSnap),
      limit(pageLimit)
    )

    getDocs(ref).then(async snapshot => {
      snapshot.docs.forEach(d => {
        if (!settings[d.data().settingId])
          settings[d.data().settingId] = undefined
      })

      for (const settingId of Object.keys(settings)) {
        if (settings[settingId]) continue

        try {
          const setting = await getDoc(
            doc(firestore, `/Users/${user.uid}/SyncSettings/${settingId}`)
            .withConverter(syncSettingConverter)
          )
          settings[settingId] = setting.data()
        }
        catch (err) {
          console.error(err)
        }
      }

      const logs = snapshot.docs
      .map(d => d.data())
      .map(log => ({log, setting: settings[log.settingId]} as Activity))

      setActivities([...activities, ...logs])
      setSettings(settings)
      setLastSnap(
        0 < snapshot.docs.length
        ? snapshot.docs[snapshot.docs.length - 1]
        : undefined
      )
    })
  }

  const menu = useRef({} as MainMenuControl)
  return (
    <>
      <div>
        <MainMenu ref={menu} current="/log" />
        {/* Content area */}
        <div className="md:pl-60">
          <div className="overflow-hidden max-w-full mx-auto flex flex-col md:px-6 xl:px-8">
            <div className="sticky top-0 z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => menu.current.setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-4 md:px-0">
                <div className="flex-1 flex"></div>
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Profile dropdown */}
                  <UserMenu />
                </div>
              </div>
            </div>
            <main className="flex-1">
              <div className="relative max-full mx-auto px-0">
                <div className="pt-10 pb-16">
                  <div className="px-4 sm:px-6 md:px-0">
                    <h1 className="text-3xl font-extrabold text-gray-900">
                      Activity logs
                    </h1>
                    { q.get('setting') ? (<div>{ q.get('setting') }</div>) : undefined }
                  </div>
                  <div className="px-4 sm:px-6 md:px-0">
                    <div className="py-6">
                      <div className="mt-8 flex flex-col">
                        <div className="overflow-x-auto">
                          <div className="inline-block min-w-full py-2 align-middle">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="text-left text-sm text-gray-400">
                                <tr className="">
                                  <th
                                    scope="col"
                                    className="py-1 px-2 font-normal whitespace-nowrap"
                                  >
                                    Status
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-1 px-2 font-normal whitespace-nowrap"
                                  >
                                    Time
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-1 px-2 font-normal whitespace-nowrap"
                                  >
                                    Sync setting
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-1 px-2 font-normal whitespace-nowrap"
                                  >
                                    Summary
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 text-base text-gray-500">
                                {activities.map((activity) => (
                                  <tr key={activity.log.id}>
                                    <td className="whitespace-nowrap py-4 px-2 text-base text-gray-900">
                                      <a
                                        href="#"
                                        className={classNames(
                                          activity.log.success
                                            ? "bg-green-500 text-white font-medium"
                                            : "bg-gray-300 text-gray-400 font-light",
                                          "rounded-full text-sm px-2 py-1 relative inline-block "
                                        )}
                                      >
                                        {activity.log.success
                                          ? activity.log.diff &&
                                            (
                                              0 < activity.log.diff.append.length ||
                                              0 < activity.log.diff.update.length ||
                                              0 < activity.log.diff.remove.length ||
                                              0 < (activity.log.diff.fail?.length || 0)
                                            ) ? "Synced" : "Success"
                                          : "Failed"}
                                      </a>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-2">
                                      <span className="text-sm text-gray-600">
                                        {
                                          activity.log.timestamp
                                          ? activity.log.timestamp.toFormat('MM/dd/yyyy HH:mm:ss')
                                          : 'N/A'
                                        }
                                      </span>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-2">
                                      { activity.setting && (
                                        <Link to={`/?id=${activity.setting.id}`} style={{display:'flex'}}>
                                          { (() => {
                                            const IconComponent = getService(activity.setting.left.serviceId)?.icon
                                            return IconComponent ? (<IconComponent className="w-4" />) : undefined
                                          })() }
                                          <ArrowSmallRightIcon className="w-4 shrink-0" />
                                          { (() => {
                                            const IconComponent = getService(activity.setting.right.serviceId)?.icon
                                            return IconComponent ? (<IconComponent className="w-4" />) : undefined
                                          })() }
                                        </Link>
                                      )}
                                    </td>
                                    <td className="py-4 px-2">
                                      <p className="text-sm text-gray-600">
                                        {/* MemberSync{" "}
                                        { activity.log.direction === 'left-to-right'
                                          ? activity.log.success
                                            ? `found that ${activity.setting.left.tokenId} was added`
                                            : ''
                                          : activity.log.direction === 'right-to-left'
                                            ? activity.log.success
                                              ? `added ${activity.log.diff.create.join(',')}`
                                              : `failed to add ${activity.log.diff.create.join(',')}`
                                            : '' }
                                        {" "}
                                        on{" "}
                                        <a href="" className="font-bold">
                                          { activity.log.direction === 'left-to-right'
                                            ? ServiceProviderMap[ServiceMap[activity.setting.left.serviceId].providerId].label
                                              : activity.log.direction === 'right-to-left'
                                              ? ServiceProviderMap[ServiceMap[activity.setting.right.serviceId].providerId].label
                                            : '' }
                                        </a>
                                        . */}
                                        { activity.log.message }
                                      </p>

                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="pt-6 bg-white px-2 py-3 flex items-center justify-between border-t border-gray-200">
                              <div className="flex-1 flex justify-between sm:hidden">
                                <a
                                  href="#"
                                  className="relative inline-flex items-center gap-2 text-sm font-base text-gray-600"
                                >
                                  <ChevronLeftIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  Previous
                                </a>
                                <a
                                  href="#"
                                  className="relative inline-flex items-center gap-2 text-sm font-base text-gray-600"
                                >
                                  Next
                                  <ChevronRightIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </a>
                              </div>
                              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                <div>
                                  <p className="text-sm text-gray-500">
                                    Showing{" "}
                                    <span className="font-medium">{ activities.length }</span>{" "}
                                    results
                                  </p>
                                </div>
                                <div>
                                  <nav
                                    className="relative z-0 inline-flex items-center gap-4 -space-x-px"
                                    aria-label="Pagination"
                                  >
                                    { lastSnap && (
                                      <a
                                        className="relative inline-flex items-center text-sm font-base text-gray-600"
                                        onClick={loadMore}
                                      >
                                        <span className="sr-only">Next</span>
                                        <ChevronRightIcon
                                          className="h-4 w-4"
                                          aria-hidden="true"
                                        />
                                      </a>
                                    ) }
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
