import { useContext, useEffect, useRef, useState } from "react"
import { collection, addDoc, getDocs, query, where, onSnapshot } from "firebase/firestore"

import { checkoutSessionConverter, Price, priceConverter, Product, productConverter } from "../types"
import { ProgressContext, UserContext } from "../contexts"
import { useFirestore } from "../composables"
// import { Progress } from "./Progress"

type PlanSelectorProps = {
  show     : boolean;
  onClose? : () => void;
}

export const PlanSelector = ({show, onClose}: PlanSelectorProps) => {
  if (!show) return null

  const { user } = useContext(UserContext)
  if (!user) return null

  const { firestore } = useFirestore()

  const close = () => {
    if (onClose) onClose()
  }

  const [products, setProducts] = useState<Product[]>([])
  const [prices, setPrices] = useState<{[product_id: string]: {[price_id: string]: Price}}>({})
  // const [progress, setProgress] = useState<string | null>('Loading')
  const { showProgress } = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  useEffect(() => {
    getDocs(query(
      collection(firestore, '/Products').withConverter(productConverter),
      where('active', '==', true),
    )).then(async snapshot => {
      try {
        const _products : Product[] = []
        const _prices : {[product_id: string]: {[price_id: string]: Price}} = {}
        for (const product of snapshot.docs.map(doc => doc.data())) {
          const priceSnapshot = await getDocs(query(
            collection(firestore, `/Products/${product.id}/prices`).withConverter(priceConverter),
            where('active', '==', true),
          ))
          _prices[product.id] = Object.fromEntries(priceSnapshot.docs.map(doc => [doc.id, doc.data()]))
          _products.push(product)
        }
        setProducts(_products)
        setPrices(_prices)
        // setProgress(null)
        progress.current?.()
      }
      catch (err) {
        console.error(err)
      }
    }).catch(console.error)
  }, [])

  const checkout = async (price: Price) => {
    // setProgress('Preparation in progress...')
    progress.current = showProgress('Preparation in progress...')
    try {
      const sessionsRef = collection(firestore, `/Users/${user.uid}/checkout_sessions`).withConverter(checkoutSessionConverter)
      const docRef = await addDoc(sessionsRef, {
        id         : null,
        price      : price.id,
        success_url: window.location.origin,
        cancel_url : window.location.origin,
      })
      const unsubscribe = onSnapshot(docRef, async doc => {
        const session = doc.data()
        if (!session) {
          return
        }
        if (session.error) {
          alert(`An error occered: ${session.error}`)
          console.error(session)
          progress.current?.()
        }

        if (session.url) {
          unsubscribe()
          window.location.assign(session.url)
        }
      })
    }
    catch (err) {
      alert(`An error occered: ${err}`)
      console.error(err)
      // setProgress(null)
      progress.current?.()
    }
  }

  return (
    <div className="msx-modal-backdrop">
      <div className="msx-modal">
        <div className="msx-modal-header">
          <div className="msx-modal-header-left">
            <div className="msx-modal-header-title">Select your preferred plan</div>
          </div>
          <div className="msx-modal-header-right">
            <button
              type="button"
              className="msx-close-btn"
              onClick={close}
            />
          </div>
        </div>
        <div className="msx-modal-content">
          <div className="flex flex-row gap-6">
            { products.map((product, i) => {
              return prices[product.id] && Object.values(prices[product.id]).map((price, j) => {
                return (
                  <button
                    key={`price-${i}-${j}`}
                    className="msx-btn grow flex flex-col items-center gap-2 px-4 py-6"
                    onClick={ev => {ev.preventDefault(); checkout(price)}}
                  >
                    <div className="text-xl font-bold">{product.name}</div>
                    {price.interval === 'month' ? 'Monthly' : 'Yearly'} - {price.currency} {price.unit_amount / 100}
                    <div className="mt-4 w-full">
                      <div className="msx-btn msx-btn-primary font-bold w-full">Upgrade</div>
                    </div>
                  </button>
                )
              })
            })}
          </div>
        </div>
        {/* Action buttons */}
        <div className="msx-modal-footer">
          <button
            type="button"
            className="msx-btn msx-btn-cancel"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}
