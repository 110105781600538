// import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore"
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot } from "./firebaseImporter"

import { Credentials, TokenInfo } from "google-auth-library"
import { ServiceProviderIds } from "./service"

export type ServiceToken = {
  id          : string | null;
  providerId  : ServiceProviderIds;
  credentials : Credentials;
  info        : TokenInfo;
}

export const tokenConverter : FirestoreDataConverter<ServiceToken> = {
  toFirestore(token: ServiceToken) : DocumentData {
    return {
      providerId  : token.providerId,
      credentials : token.credentials,
      info        : token.info,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot) : ServiceToken {
    const token = snapshot.data()
    return {
      id          : snapshot.id,
      providerId  : token.providerId,
      credentials : token.credentials,
      info        : token.info,
    }
  },
}
