import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore"

export type LineItem = {
  price            : string;
  quantity         : number;
  dynamic_tax_rates: string[];
}

export type CheckoutSession = {
  id         : string | null;
  price      : string;
  success_url: string;
  cancel_url : string;
  url?       : string | null;
  error?     : string | null;
  trial_from_plan?: boolean | null;   // Handling trials
  allow_promotion_codes?: boolean | null; // Applying discount, coupon, promotion codes
  promotion_code?: string | null;     // Applying promotion codes programmatically
  automatic_tax?: boolean | null;     // Automatic tax calculation with Stripe Tax
  tax_id_collection?: boolean | null; // Automatic tax calculation with Stripe Tax
  line_items?: LineItem[] | null;     // Applying tax rates dynamically
  tax_rates? : string[] | null;       // Applying static tax rates
  collect_shipping_address?: boolean | null; // Collecting a shipping address during checkout
  metadata?  : {[key:string]: string} | null; // Setting metadata on the subscription
}

export const checkoutSessionConverter : FirestoreDataConverter<CheckoutSession> = {
  toFirestore(session: CheckoutSession) : DocumentData {
    const data : DocumentData = {
      price      : session.price,
      success_url: session.success_url,
      cancel_url : session.cancel_url,
      url        : session.url   || null,
      error      : session.error || null,
    }

    if (typeof(session.trial_from_plan) === 'boolean') data.trial_from_plan = session.trial_from_plan
    if (typeof(session.allow_promotion_codes) === 'boolean') data.allow_promotion_codes = session.allow_promotion_codes
    if (typeof(session.promotion_code) === 'string') data.promotion_code = session.promotion_code
    if (typeof(session.automatic_tax) === 'boolean') data.automatic_tax = session.automatic_tax
    if (typeof(session.tax_id_collection) === 'boolean') data.tax_id_collection = session.tax_id_collection
    if (Array.isArray(session.line_items)) data.line_items = session.line_items
    if (Array.isArray(session.tax_rates)) data.tax_rates = session.tax_rates
    if (typeof(session.collect_shipping_address) === 'boolean') data.collect_shipping_address = session.collect_shipping_address
    if (typeof(session.metadata) === 'object') data.metadata = session.metadata

    return data
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, _options: SnapshotOptions) : CheckoutSession {
    const session = snapshot.data()
    return {
      id         : snapshot.id,
      price      : session.price,
      success_url: session.success_url,
      cancel_url : session.cancel_url,
      url        : session.url || null,
      error      : session.error || null,
      trial_from_plan: typeof(session.trial_from_plan) === 'boolean' ? session.trial_from_plan : null,
      allow_promotion_codes: typeof(session.allow_promotion_codes) === 'boolean' ? session.allow_promotion_codes : null,
      promotion_code: session.promotion_code || null,
      automatic_tax: typeof(session.automatic_tax) === 'boolean' ? session.automatic_tax : null,
      tax_id_collection: typeof(session.tax_id_collection) === 'boolean' ? session.tax_id_collection : null,
      line_items : Array.isArray(session.line_items) ? session.line_items.map(item => ({
        price            : item.price    || '',
        quantity         : item.quantity || 0,
        dynamic_tax_rates: Array.isArray(item.dynamic_tax_rates) ? item.dynamic_tax_rates : [],
      })) : null,
      tax_rates  : Array.isArray(session.tax_rates) ? session.tax_rates : null,
      collect_shipping_address: typeof(session.collect_shipping_address) === 'boolean' ? session.collect_shipping_address : null,
      metadata   : session.metadata && 0 < Object.keys(session.metadata).length ? session.metadata : null,
    }
  },
}
