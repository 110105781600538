import { DateTime } from 'luxon'
import { PermissionItem } from '../types'
import { ServiceIds } from './service'

type SyncTargetBase = {
  serviceId   : ServiceIds;
  tokenId     : string | null;
  permissions : PermissionItem[];
}

export type SyncTargetFileItem = {
  id                   : string | null;
  name                 : string | null;
  type                 : string | null;
  description          : string | null;
  iconLink             : string | null;
  lastModifyingUserName: string | null;
  createdTime          : string | null;
}

export type SyncTargetCalendarItem = {
  id         : string | null;
  name       : string | null;
  description: string | null;
  color      : string | null;
}

export type SyncTargetEventItem = {
  id         : string | null;
  name       : string | null;
  description: string | null;
}

export type SyncTargetSheetItem = {
  title      : string | null;
  columnCount: number | null;
  rowCount   : number | null;
  color      : {
    red  : number;
    green: number;
    blue : number;
    alpha: number;
  } | null;
}

export type SyncTargetCellItem = {
  id         : string | null;
  range      : string | null;
  values     : string[] | null;
}

export type SyncTargetAdminGroupItem = {
  id  : string | null;
  name: string | null;
  description: string | null;
  directMembersCount: number | null;
}

export type SyncTargetAdminOrgUnitItem = {
  id  : string | null;
  name: string | null;
  path: string | null;
  description: string | null;
}

type SyncTargetUnknown = {
  serviceId: 'unknown';
}

type SyncTargetGoogleDriveFile = {
  serviceId: 'google-drive-file';
  file     : SyncTargetFileItem | null;
  defaultRoleId: string | null;
}

type SyncTargetGoogleDriveFolder = {
  serviceId: 'google-drive-folder';
  folder   : SyncTargetFileItem | null;
  defaultRoleId: string | null;
}

type SyncTargetGoogleCalendarAttendee = {
  serviceId: 'google-calendar-attendee';
  calendar : SyncTargetCalendarItem | null;
  event    : SyncTargetEventItem | null;
}

type SyncTargetGoogleCalendarPermission = {
  serviceId: 'google-calendar-permission';
  calendar : SyncTargetCalendarItem | null;
  defaultRoleId: string | null;
}

type SyncTargetGoogleSheetsCell = {
  serviceId: 'google-sheets-cell';
  file    : SyncTargetFileItem | null;
  sheet   : SyncTargetSheetItem | null;
  cell    : SyncTargetCellItem | null;
}

type SyncTargetGoogleAdminGroup = {
  serviceId: 'google-admin-group';
  group    : SyncTargetAdminGroupItem | null;
  defaultRoleId: string | null;
}

type SyncTargetGoogleAdminOrgUnit = {
  serviceId: 'google-admin-org-unit';
  orgUnit  : SyncTargetAdminOrgUnitItem | null;
}

export type SyncTarget = SyncTargetBase & (
  SyncTargetUnknown |
  SyncTargetGoogleDriveFile | SyncTargetGoogleDriveFolder |
  SyncTargetGoogleCalendarPermission | SyncTargetGoogleCalendarAttendee |
  SyncTargetGoogleSheetsCell |
  SyncTargetGoogleAdminGroup | SyncTargetGoogleAdminOrgUnit
)

export type SyncSettingStatSynced = {
  last7days: number;
  last30days: number;
  last365days: number;
}

export type SyncSetting = {
  id       : string | null;
  active   : boolean;
  left     : SyncTarget;
  right    : SyncTarget;
  stat     : { synced : SyncSettingStatSynced };
  timestamp: DateTime;
}

export const InitialSyncTarget : SyncTarget = {
  serviceId    : 'google-drive-file',
  tokenId      : null,
  file         : null,
  permissions  : [],
  defaultRoleId: null,
}

export const InitialSyncSetting : SyncSetting = {
  id       : null,
  active   : true,
  left     : InitialSyncTarget,
  right    : InitialSyncTarget,
  stat     : {synced: {last7days: 0, last30days: 0, last365days: 0}},
  timestamp: DateTime.local(),
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToSyncTarget = (target: any) : SyncTarget => {
  if (!target || typeof(target) !== 'object' || !target.serviceId) {
    return { serviceId : 'unknown', tokenId: '', permissions: [] }
  }

  switch (target.serviceId) {
    case 'google-drive-file':
      return {
        serviceId    : 'google-drive-file',
        tokenId      : target.tokenId,
        permissions  : target.permissions || [],
        file         : target.file || null,
        defaultRoleId: target.defaultRoleId || null,
      }
    case 'google-drive-folder':
      return {
        serviceId    : 'google-drive-folder',
        tokenId      : target.tokenId,
        permissions  : target.permissions || [],
        folder       : target.folder || null,
        defaultRoleId: target.defaultRoleId || null,
      }
    case 'google-calendar-permission':
      return {
        serviceId  : 'google-calendar-permission',
        tokenId    : target.tokenId,
        permissions: target.permissions || [],
        calendar   : target.calendar || null,
        defaultRoleId: target.defaultRoleId || null,
      }
    case 'google-calendar-attendee':
      return {
        serviceId  : 'google-calendar-attendee',
        tokenId    : target.tokenId,
        permissions: target.permissions || [],
        calendar   : target.calendar || null,
        event      : target.event || null,
      }
    case 'google-sheets-cell':
      return {
        serviceId  : 'google-sheets-cell',
        tokenId    : target.tokenId,
        permissions: target.permissions || [],
        file       : target.file || null,
        sheet      : target.sheet || null,
        cell       : target.cell || null,
      }
    case 'google-admin-group':
      return {
        serviceId    : 'google-admin-group',
        tokenId      : target.tokenId,
        permissions  : target.permissions || [],
        group        : target.group || null,
        defaultRoleId: target.defaultRoleId || null,
      }
    case 'google-admin-org-unit':
      return {
        serviceId  : 'google-admin-org-unit',
        tokenId    : target.tokenId,
        permissions: target.permissions || [],
        orgUnit    : target.orgUnit || null,
      }
    default:
      return { serviceId : 'unknown', tokenId: 'unknown converter', permissions: [] }
  }
}

import { DocumentData, QueryDocumentSnapshot, FirestoreDataConverter, serverTimestamp } from './firebaseImporter'

export const syncSettingConverter : FirestoreDataConverter<SyncSetting> = {
  toFirestore(setting: SyncSetting) : DocumentData {
    return {
      active   : setting.active,
      trigger  : setting.left,
      actions  : [setting.right],
      stat     : setting.stat,
      timestamp: setting.timestamp?.toJSDate() || serverTimestamp(),
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot) : SyncSetting {
    const setting = snapshot.data()
    return {
      id       : snapshot.id,
      active   : setting.active,
      left     : convertToSyncTarget(setting.trigger),
      right    : convertToSyncTarget(setting.actions[0]),
      stat     : setting.stat,
      timestamp: setting.timestamp ? DateTime.fromJSDate(setting.timestamp.toDate()) : DateTime.local(),
    }
  },
}
