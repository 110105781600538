import { DocumentData, QueryDocumentSnapshot, FirestoreDataConverter, serverTimestamp } from './firebaseImporter'
import { DateTime } from 'luxon'
import { DifferenceOfPermissions } from '../types'

export type Log = {
  id         : string | undefined; // undefined = create
  timestamp? : DateTime;
  level      : 'debug' | 'info' | 'warn' | 'error';
  success    : boolean;
  channelId  : string;
  settingId  : string;
  diff       : DifferenceOfPermissions;
  message    : string;
  payload?   : any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const logConverter : FirestoreDataConverter<Log> = {
  toFirestore(log: Log) : DocumentData {
    return {
      timestamp : log.timestamp?.toJSDate() || serverTimestamp(),
      level     : log.level,
      success   : log.success,
      channelId : log.channelId,
      settingId : log.settingId,
      diff      : log.diff || { append: [], update: [], delete: [], fail: [] },
      message   : log.message,
      payload   : log.payload || null,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot) : Log {
    const log = snapshot.data()
    return {
      id        : snapshot.id,
      timestamp : DateTime.fromJSDate(log.timestamp.toDate()),
      level     : log.level,
      success   : log.success,
      channelId : log.channelId,
      settingId : log.settingId,
      diff      : {
        append: (log.diff && log.diff.append && Array.isArray(log.diff.append)) ? log.diff.append : [],
        update: (log.diff && log.diff.update && Array.isArray(log.diff.update)) ? log.diff.update : [],
        remove: (log.diff && log.diff.remove && Array.isArray(log.diff.remove)) ? log.diff.remove : [],
        fail  : (log.diff && log.diff.fail && Array.isArray(log.diff.fail)) ? log.diff.fail : [],
      },
      message   : log.message,
      payload   : log.payload,
    }
  },
}
