import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { collection, onSnapshot } from "firebase/firestore"

import { classNames } from "../../lib"
import {
  SyncTarget, tokenConverter,
  Services, ServiceToken, getService,
  getServiceProviderFromServiceId,
  getAccountProviderFromServiceId,
  getServiceProvider,
  InitialSyncTarget,
} from "../../types"
import { ProgressContext, UserContext } from "../../contexts"
import { useFirestore, useFunctions } from "../../composables"

import { SyncTargetGoogleDriveFile } from "./GoogleDriveFile"
import { SyncTargetGoogleDriveFolder } from "./GoogleDriveFolder"
import { SyncTargetGoogleCalendarPermission } from "./GoogleCalendarPermission"
import { SyncTargetGoogleCalendarAttendee } from "./GoogleCalendarAttendee"
import { SyncTargetGoogleSheetsCell } from "./GoogleSheetsCell"
import { SyncTargetGoogleAdminGroup } from "./GoogleAdminGroup"
import { SyncTargetGoogleAdminOrgUnit } from "./GoogleAdminOrgUnit"
import { PlusIcon } from "@heroicons/react/24/outline"

export type SyncTargetEditorProps = {
  type    : 'src' | 'dst';
  target   : SyncTarget;
  onChange : (target: SyncTarget) => void;
}

const resetServiceParams = (target: SyncTarget) => {
  switch(target.serviceId) {
    case 'google-drive-file': {
      target.file = null
      target.defaultRoleId = null
    } break
    case 'google-drive-folder': {
      target.folder = null
      target.defaultRoleId = null
    } break
    case 'google-calendar-attendee': {
      target.calendar = null
      target.event = null
    } break
    case 'google-calendar-permission': {
      target.calendar = null
    } break
    case 'google-sheets-cell': {
      target.sheet = null
    } break
    case 'google-admin-group': {
      target.group = null
      target.defaultRoleId = null
    } break
    case 'google-admin-org-unit': {
      target.orgUnit = null
    } break
  }
  return target
}

export const SyncTargetEditor = ({type, target, onChange} : SyncTargetEditorProps) => {
  const { user } = useContext(UserContext)
  const { showProgress } = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  const { firestore } = useFirestore()
  const { generateAuthUrl } = useFunctions()

  type TokenState = {
    list      : ServiceToken[];
    map       : {[tid:string]: ServiceToken};
    byProvider: {[pid:string]: ServiceToken[]};
  }

  const InitialTokenState : TokenState = {
    list      : [],
    map       : {},
    byProvider: {},
  }

  const [tokenState, setTokenState] = useState<TokenState>(InitialTokenState)
  const [filterWord, setFilterWord] = useState<string>('')

  const initialized = useRef<boolean>(false)

  const resetedTarget = useRef<SyncTarget>()
  resetedTarget.current = resetServiceParams({...target, tokenId: null})

  useEffect(() => {
    if (!user) return
    progress.current = showProgress('Loading...')
    const tokensRef = collection(firestore, `/Users/${user.uid}/Tokens`).withConverter(tokenConverter)
    return onSnapshot(tokensRef, tokensSnap => {
      const state : TokenState = {...InitialTokenState}
      state.list = tokensSnap.docs.map(doc => doc.data())
      state.list.map(t => {
        if (t.id) state.map[t.id] = t
      })
      for (const pid of ['google-drive', 'google-calendar', 'google-sheets', 'google-admin']) {
        state.byProvider[pid] = state.list.filter(t => t.providerId === pid)
      }
      setTokenState(state)
      if (!initialized.current) {
        initialized.current = true
      }
      else {
        if (resetedTarget.current) {
          onChange(resetedTarget.current)
        }
      }
      progress.current?.()
    })
  }, [])

  if (!user) return (<div>loading</div>)
  return (
    <>
      <div className="flex flex-col w-full gap-2 sm:gap-3 p-6 border rounded">
        <div className="space-y-1">
          <div>
            <label
              htmlFor="data-term"
              className="block text-sm font-medium text-gray-500"
            >
              Sync target
            </label>
          </div>
          <div>
            <Listbox
              value={target.serviceId}
              onChange={sid => {
                const update: SyncTarget = {...target, tokenId: null}
                update.serviceId = sid
                resetServiceParams(update)
                onChange(update)
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative">
                    <Listbox.Button className="msx-select">
                      <div className="flex flex-col gap-1">
                        <span className="block truncate">
                          { getService(target.serviceId)?.label }
                        </span>
                        <span className="flex items-center gap-3">
                          <img
                            src={getServiceProviderFromServiceId(target.serviceId)?.icon}
                            alt=""
                            className="flex-shrink-0 h-6 w-6 rounded-full"
                          />
                          <span className="block truncate font-light text-sm text-gray-600">
                             { getServiceProviderFromServiceId(target.serviceId)?.label }
                          </span>
                        </span>
                      </div>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                        <div className="py-2 px-3">
                          <input
                            type="text"
                            className="msx-input"
                            placeholder="Search ..."
                            defaultValue={filterWord}
                            onChange={ev => setFilterWord(ev.target.value)}
                          />
                        </div>
                        {Services
                        .filter(service => process.env.NODE_ENV === 'development' || service.visible[type])
                        .filter(service => service.label.toLowerCase().includes(filterWord.toLowerCase()))
                        .map((service) => (
                          <Listbox.Option
                            key={service.id}
                            className={({ selected }) =>
                              classNames(
                                "msx-dropdown-menu-item",
                                selected || target.serviceId === service.id
                                  ? "msx-dropdown-menu-item-selected"
                                  : ""
                              )
                            }
                            value={service.id}
                          >
                            <div className="flex flex-col text-left gap-1">
                              <span className="font-medium">
                                {service.label}
                              </span>
                              <span className="msx-dropdown-menu-subtext">
                                { getServiceProviderFromServiceId(service.id)?.label }
                                { process.env.NODE_ENV === 'development' && !service.visible[type] && (
                                  <span> (dev)</span>
                                )}
                              </span>
                            </div>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
        <div className="space-y-1">
          <div>
            <label
              htmlFor="connected-account"
              className="block text-sm font-medium text-gray-500"
            >
              Account
            </label>
          </div>
          <div>
            <Listbox
              value={target.tokenId}
              onChange={tid => {
                if (tid === null) {
                  const provider = getServiceProviderFromServiceId(target.serviceId)
                  if (!provider) {
                    console.error('provider not found')
                    progress.current?.()
                    return
                  }
                  generateAuthUrl({
                    providerId: provider.id,
                    settingId : null,
                  }).then(res => {
                    if (res.data.status != 200) {
                      console.error(res.data)
                      progress.current?.()
                      return
                    }
                    if (!res.data.url) {
                      console.error(res.data)
                      progress.current?.()
                      return
                    }

                    resetServiceParams(target)
                    onChange(target)

                    window.open(res.data.url, 'membersync-auth', 'width=500,height=600')
                    // generateAuthUrl
                    // -> redirect to google/oauth
                    // -> google/oauth
                    // -> redirect to app.membersync.io w/ code, scope, state
                    // -> setToken({code, scope, state})
                    // ->
                  })
                }
                else {
                  // Set the token
                  const update: SyncTarget = {...target, tokenId: tid}
                  resetServiceParams(update)
                  onChange(update)
                }
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative">
                    <Listbox.Button className="relative w-full msx-select">
                      <span className="flex items-center gap-3">
                        <img
                          src={getServiceProviderFromServiceId(target.serviceId)?.icon}
                          alt=""
                          className="flex-shrink-0 h-6 w-6 rounded-full"
                        />
                        <span className="block">
                          {(target.tokenId && tokenState.map[target.tokenId]) ? (
                            tokenState.map[target.tokenId].info.email || tokenState.map[target.tokenId].info.aud
                          ) : (
                            <span className="text-gray-400">Select account ...</span>
                          )}
                        </span>
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-px w-full msx-dropdown-menu">
                        {(tokenState.byProvider[getServiceProviderFromServiceId(target.serviceId)?.id || ''] || []).map(token => (
                          <Listbox.Option
                            key={token.id}
                            className={({ selected }) =>
                              classNames(
                                "msx-dropdown-menu-item",
                                selected ? "msx-dropdown-menu-item-selected" : ""
                              )
                            }
                            value={token.id}
                          >
                            <div className="flex items-center gap-3">
                              <img
                                src={getServiceProvider(token.providerId)?.icon}
                                alt=""
                                className="flex-shrink-0 h-6 w-6 rounded-full"
                              />
                              <span className="font-medium">
                                { token.info.email || token.info.aud }
                              </span>
                            </div>
                          </Listbox.Option>
                        ))}
                        <Listbox.Option
                          key={`connect-token`}
                          value={null}
                          className="cursor-pointer select-none relative py-2 pl-3 hover:bg-slate-100 hover:text-slate-800"
                        >
                          <div className="flex items-center gap-3">
                            <img
                              src={getAccountProviderFromServiceId(target.serviceId)?.icon}
                              alt=""
                              className="flex-shrink-0 h-6 w-6 rounded-full"
                            />
                            <div className="flex flex-row items-center gap-1">
                              <PlusIcon className="w-5 h-5 text-indigo-600" />
                              <span className="text-indigo-600">
                                Authorize a new account
                              </span>
                            </div>
                          </div>
                        </Listbox.Option>
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>

        {
          // target specific data
          (() => {
            switch (target.serviceId) {
              case 'google-drive-file': {
                return (
                  <SyncTargetGoogleDriveFile
                    type={type}
                    target={target}
                    onChange={onChange}
                  />
                )
              }
              case 'google-drive-folder': {
                return (
                  <SyncTargetGoogleDriveFolder
                    type={type}
                    target={target}
                    onChange={onChange}
                  />
                )
              }
              case 'google-calendar-permission': {
                return (
                  <SyncTargetGoogleCalendarPermission
                    type={type}
                    target={target}
                    onChange={onChange}
                  />
                )
              }
              case 'google-calendar-attendee': {
                return (
                  <SyncTargetGoogleCalendarAttendee
                    type={type}
                    target={target}
                    onChange={onChange}
                  />
                )
              }
              case 'google-sheets-cell': {
                return (
                  <SyncTargetGoogleSheetsCell
                    type={type}
                    target={target}
                    onChange={onChange}
                  />
                )
              }
              case 'google-admin-group': {
                return (
                  <SyncTargetGoogleAdminGroup
                    type={type}
                    target={target}
                    onChange={onChange}
                  />
                )
              }
              case 'google-admin-org-unit': {
                return (
                  <SyncTargetGoogleAdminOrgUnit
                    type={type}
                    target={target}
                    onChange={onChange}
                  />
                )
              }
              default: {
                return (
                  <div className="text-white">{target.serviceId}</div>
                )
              }
            }
          })()
        }
      </div>
    </>
  )
}

export type SyncTargetViewerProps = {
  type?   : 'src' | 'dst';
  target  : SyncTarget;
}

const getTargetName = (target: SyncTarget) => {
  switch(target.serviceId) {
    case 'google-drive-file': {
      return target.file?.name
    }
    case 'google-drive-folder': {
      return target.folder?.name
    }
    case 'google-calendar-permission': {
      return target.calendar?.name
    }
    case 'google-calendar-attendee': {
      return target.event?.name
    }
    case 'google-sheets-cell': {
      return `${target.file?.name} / ${target.sheet?.title}`
    }
    case 'google-admin-group': {
      return target.group?.name
    }
    case 'google-admin-org-unit': {
      return target.orgUnit?.name
    }
    default: {
      return 'unknown'
    }
  }
}

export const TargetView = ({target} : SyncTargetViewerProps) => {
  if (target.serviceId === 'unknown') {
    return (<div style={{color:'black'}}>unknown</div>)
  }

  return (
    <div className="inline-flex items-center gap-4">
      <img
        className="w-6 h-6 shrink-0"
        src={getServiceProviderFromServiceId(target.serviceId)?.icon}
      />
      <div className="grow space-y-0.5">
        <div className="text-slate-700 font-medium">{getService(target.serviceId)?.label}</div>
        <div className="flex items-center gap-2">
          <div className="font-medium text-slate-400">
            {getServiceProviderFromServiceId(target.serviceId)?.label}
          </div>
          <div className="w-1 h-1 rounded-full bg-slate-300" />
          <div className="flex items-center gap-1 font-base text-slate-400">
            { (() => {
              const IconComponent = getService(target.serviceId)?.icon
              return IconComponent ? (<IconComponent className="w-4" />) : undefined
            })() }
            <span className="truncate">{ getTargetName(target) }</span>
          </div>
        </div>
      </div>
    </div>
  )
}
