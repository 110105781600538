import { useState, useEffect, Fragment, useContext, useRef } from "react"
import * as EmailValidator from 'email-validator'
import { Listbox, Transition } from "@headlessui/react"
import { DocumentTextIcon, FolderIcon } from "@heroicons/react/24/outline"
import { classNames } from "../../lib"
import { SyncTargetCellItem, SyncTargetFileItem, SyncTargetSheetItem } from "../../types"
import { useFunctions } from "../../composables"
import { ProgressContext, UserContext } from "../../contexts"
import { SyncTargetEditorProps } from "."
import { updateToken } from "../../lib/updateToken"

const cellName = (column: number) : string => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const a = Math.floor(column / alphabet.length)
  const b = column % alphabet.length
  return (a > 0 ? alphabet[a-1] : '') + alphabet[b]
}

export const SyncTargetGoogleSheetsCell = ({target, onChange} : SyncTargetEditorProps) => {
  if (target.serviceId !== 'google-sheets-cell') return null
  const { user } = useContext(UserContext)
  if (!user) return null

  const [filterWordFile, setFilterWordFile] = useState<string>('')
  const [filterWordSheet, setFilterWordSheet] = useState<string>('')
  const [filterWordColumn, setFilterWordColumn] = useState<string>('')

  const { getGoogleDriveFiles, getGoogleSheetsSheet, getGoogleSheetsCell } = useFunctions()
  const [files, setFiles] = useState<SyncTargetFileItem[]>([])
  const [sheets, setSheets] = useState<SyncTargetSheetItem[]>([])
  const [cells, setCells] = useState<SyncTargetCellItem[]>([])
  const { showProgress } = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  useEffect(() => {
    if (!target.tokenId) return

    const tokenId = target.tokenId
    progress.current = showProgress('Loading...')

    getGoogleDriveFiles({tokenId, query: "mimeType = 'application/vnd.google-apps.spreadsheet'"}).then(res => {
      if (res.data.status != 200 || !res.data.files) {
        console.error(res.data, tokenId)
        if (res.data.data && res.data.data.error === 'invalid_grant') {
          updateToken(user, tokenId)
        }
        else {
          alert(res.data.data?.description || res.data.statusText || 'Failed to load files.')
        }
        progress.current?.()
        return
      }
      setFiles(res.data.files.map(file => ({
        id: file.id || null,
        name: file.name || null,
        type: file.mimeType || null,
        description: file.description || null,
        iconLink: file.iconLink || null,
        lastModifyingUserName: file.lastModifyingUser?.displayName || null,
        createdTime: file.createdTime || null,
      })))
      progress.current?.()
    }).catch(err => {
      console.error(err)
      progress.current?.()
    })
  }, [target.tokenId])

  useEffect(() => {
    if (!target.tokenId) return
    if (!target.file?.id) return

    const tokenId = target.tokenId

    progress.current = showProgress('Loading...')
    getGoogleSheetsSheet({tokenId: tokenId, fileId: target.file.id}).then(res => {
      if (res.data.status != 200 || !res.data.sheets) {
        console.error(res.data, tokenId)
        if (res.data.data && res.data.data.error === 'invalid_grant') {
          updateToken(user, tokenId)
        }
        else {
          alert(res.data.data?.description || res.data.statusText || 'Failed to load files.')
        }
        progress.current?.()
        return
      }
      setSheets(res.data.sheets.map(sheet => ({
        title: sheet.properties?.title || null,
        columnCount: sheet.properties?.gridProperties?.columnCount || null,
        rowCount: sheet.properties?.gridProperties?.rowCount || null,
        color: sheet.properties?.tabColor ? {
          red  : sheet.properties?.tabColor?.red   || 0,
          green: sheet.properties?.tabColor?.green || 0,
          blue : sheet.properties?.tabColor?.blue  || 0,
          alpha: sheet.properties?.tabColor?.alpha || 0,
        } : null,
      })))

      progress.current?.()
    }).catch((err) => {
      console.error(err)
      progress.current?.()
    })
  }, [target.file])

  useEffect(() => {
    if (!target.tokenId) return
    if (!target.file?.id) return
    if (!target.sheet?.title) return

    const tokenId = target.tokenId

    progress.current = showProgress('Loading...')
    getGoogleSheetsCell({
      tokenId,
      fileId: target.file.id,
      range: `${target.sheet.title}!A1:${cellName((target.sheet.columnCount || 26) - 1)}4`,
    }).then(res => {
      if (res.data.status != 200 || !res.data.values) {
        console.error(res.data, tokenId)
        if (res.data.data && res.data.data.error === 'invalid_grant') {
          updateToken(user, tokenId)
        }
        else {
          alert(res.data.data?.description || res.data.statusText || 'Failed to load files.')
        }
        progress.current?.()
        return
      }

      if (!res.data.values) return

      let columns = 0
      for (let i = 0; i < res.data.values.length; i++) {
         if ( columns < res.data.values[i].length ) {
          columns = res.data.values[i].length
        }
      }

      const transposed : string[][] = []
      for (let i = 0; i < columns; i++) {
        transposed.push([])
      }

      for (let i = 0; i < res.data.values.length; i++) {
        for (let j = 0; j < res.data.values[i].length; j++) {
          transposed[j][i] = res.data.values[i][j]
        }
      }

      setCells(transposed.map((values, idx) => ({
        id    : cellName(idx),
        range : `${target.sheet?.title || 'シート1'}!${cellName(idx)}1:${cellName(idx)}${target.sheet?.rowCount || 1000}`,
        values: values.filter(v => !!v).filter(v => EmailValidator.validate(v)),
      })).filter(column => 0 < column.values.filter(v => !!v).length))

      progress.current?.()
    }).catch((err) => {
      console.error(err)
      progress.current?.()
    })
  }, [target.sheet])

  const onChangeFile = (file: SyncTargetFileItem) => {
    onChange({...target, file, sheet: null, cell: null})
  }

  const onChangeSheet = (sheet: SyncTargetSheetItem) => {
    onChange({...target, sheet, cell: null})
  }

  return (
    <>
      {/* <Progress show={progress !== null} message={progress} /> */}
      <div className="space-y-1">
        <div>
          <label htmlFor="" className="block text-sm font-medium text-gray-500">Sheet file</label>
        </div>
        <div className="">
          <Listbox
            value={target.file}
            onChange={onChangeFile}
            disabled={!target.tokenId}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="msx-select w-full">
                    { target.file ?
                      <div className="flex items-center gap-3">
                        <span className="font-normal block truncate">
                          { target.file.name }
                        </span>
                        <span className="font-normal block truncate font-light text-gray-400">
                          { target.file.lastModifyingUserName }
                          { target.file.createdTime }
                        </span>
                      </div>
                      :
                      <span className="inline-block text-gray-400">
                        Select sheet file ...
                      </span>
                    }
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                      { 0 < files.length ? (
                        <div className="py-2 px-3">
                          <input
                            type="text"
                            className="msx-input"
                            placeholder="Search sheet file ..."
                            defaultValue={filterWordFile}
                            onChange={ev => setFilterWordFile(ev.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="py-2 px-3">
                          <span className="block text-gray-400">
                            No sheet file is found. Go to your Sheets and create your first sheet file.
                          </span>
                        </div>
                      ) }
                      {files
                      .filter(file => file.name?.toLowerCase().includes(filterWordFile.toLowerCase()))
                      .map(file => (
                        <Listbox.Option
                          key={file.id}
                          className={({ selected }) =>
                            classNames(
                              "msx-dropdown-menu-item",
                              selected || target.file?.id == file.id
                                ? "msx-dropdown-menu-item-selected"
                                : ""
                            )
                          }
                          value={file}
                        >
                          {({active}) => (
                            <>
                              <div className="flex flex-col text-left gap-1">
                                <div className="inline-flex items-center gap-2">
                                  {file.type === 'application/vnd.google-apps.folder' ? (
                                    <FolderIcon className="w-5 h-5 shrink-0" />
                                  ) : (
                                    file.iconLink ? (
                                      <img src={file.iconLink} className="w-5 h-5 shrink-0" />
                                    ) : (
                                      <DocumentTextIcon className="w-5 h-5 shrink-0" />
                                    )
                                  )}
                                  <span
                                    className={classNames(
                                      target.file?.id == file.id
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate"
                                    )}
                                  >
                                    { file.name }
                                  </span>
                                </div>
                                <div className="inline-flex items-center gap-2 pl-7">
                                  <span
                                    className={classNames(
                                      target.file?.id == file.id
                                        ? "font-normal"
                                        : "font-light",
                                      "block truncate font-light text-gray-400"
                                    )}
                                  >
                                    { /* File path */ }
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className="space-y-1">
        <div>
          <label htmlFor="" className="block text-sm font-medium text-gray-500">Worksheet</label>
        </div>
        <div className="">
          <Listbox
            value={target.sheet}
            onChange={onChangeSheet}
            disabled={!target.tokenId}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="msx-select w-full">
                    { target.sheet ?
                      <div className="flex items-center gap-3">
                        <span
                          className="inline-block w-3 h-3 rounded-full shrink-0"
                          style={{
                            backgroundColor: target.sheet.color
                              ? `rgb(${target.sheet.color.red * 255}, ${target.sheet.color.green * 255}, ${target.sheet.color.blue * 255}`
                              : 'transparent'
                          }}
                        />
                        <span className="font-normal block truncate">
                          { target.sheet.title }
                        </span>
                        <span className="font-normal block truncate font-light text-gray-400">
                          { target.sheet.columnCount } x { target.sheet.rowCount }
                        </span>
                      </div>
                      :
                      <span className="inline-block text-gray-400">
                        Select worksheet ...
                      </span>
                    }
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                      <div className="py-2 px-3">
                        <input
                          type="text"
                          className="msx-input"
                          placeholder="Search worksheet ..."
                          defaultValue={filterWordSheet}
                          onChange={ev => setFilterWordSheet(ev.target.value)}
                        />
                      </div>
                      {sheets
                      .filter(sheet => sheet.title?.toLowerCase().includes(filterWordSheet.toLowerCase()))
                      .map(sheet => (
                        <Listbox.Option
                          key={sheet.title}
                          className={({ selected }) =>
                            classNames(
                              "msx-dropdown-menu-item",
                              selected || target.sheet?.title == sheet.title
                                ? "msx-dropdown-menu-item-selected"
                                : ""
                            )
                          }
                          value={sheet}
                        >
                          <div className="flex flex-col text-left gap-1">
                            <div className="inline-flex items-center gap-2">
                              <span
                                className="inline-block h-2 w-2 rounded-full"
                                style={{
                                  backgroundColor: sheet.color
                                    ? `rgb(${sheet.color.red * 255}, ${sheet.color.green * 255}, ${sheet.color.blue * 255}`
                                    : 'transparent',
                                }} />
                              <span
                                className={classNames(
                                  target.sheet?.title === sheet.title
                                    ? "font-semibold"
                                    : "font-normal",
                                  "block truncate"
                                )}
                              >
                                { sheet.title }
                              </span>
                            </div>
                            <div className="inline-flex items-center gap-2 pl-4">
                              <span
                                className={classNames(
                                  target.sheet?.title === sheet.title
                                    ? "font-normal"
                                    : "font-light",
                                  "block truncate text-sm text-gray-400"
                                )}
                              >
                                { sheet.columnCount } x { sheet.rowCount }
                              </span>
                            </div>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className="space-y-1">
        <div>
          <label htmlFor="" className="block text-sm font-medium text-gray-500">Column</label>
        </div>
        <div className="">
          <Listbox
            value={target.cell}
            onChange={cell => onChange({...target, cell})}
            disabled={!target.tokenId}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="msx-select w-full">
                    { target.cell ?
                      <div className="flex items-center gap-3">
                        <span className="font-normal block truncate">
                          { target.cell.id }
                        </span>
                        <span className="font-normal block truncate font-light text-gray-400">
                          { target.cell.values?.filter(v => !!v).join(', ') }
                        </span>
                      </div>
                      :
                      <span className="inline-block text-gray-400">
                        Select column ...
                      </span>
                    }
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                      { 0 < cells.length ? (
                        <div className="py-2 px-3">
                          <input
                            type="text"
                            className="msx-input"
                            placeholder="Search column ..."
                            defaultValue={filterWordColumn}
                            onChange={ev => setFilterWordColumn(ev.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="py-2 px-3">
                          <span className="block text-gray-400">
                            No matching column is found. Go to your Sheets and set up an appropriate column.
                          </span>
                        </div>
                      ) }
                      {cells
                      .filter(
                        cell => cell.id?.toLowerCase().includes(filterWordColumn.toLowerCase()) ||
                        cell.values?.filter(v => !!v).join('').toLowerCase().includes(filterWordColumn.toLowerCase())
                      )
                      .map(cell => (
                        <Listbox.Option
                          key={cell.id}
                          className={({ selected }) =>
                            classNames(
                              "msx-dropdown-menu-item",
                              selected
                                ? "msx-dropdown-menu-item-selected"
                                : ""
                            )
                          }
                          value={cell}
                        >
                          <div className="flex items-center gap-3">
                            <span
                              className={classNames(
                                cell.id == target.cell?.id
                                  ? "font-semibold"
                                  : "font-normal",
                                "block truncate"
                              )}
                            >
                              { cell.id }
                            </span>
                            <span
                              className={classNames(
                                cell.id == target.cell?.id
                                  ? "font-normal"
                                  : "font-light",
                                "block truncate text-gray-400"
                              )}
                            >
                              { cell?.values?.filter(v => !!v).join(', ') }
                            </span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
    </>
  )
}
