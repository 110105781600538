import React from "react"

export type ModalDialogProps = {
  show     : boolean;
  title?   : string;
  children?: React.ReactNode;
  close    : () => void;
  onOK?    : () => void;
  onCancel?: () => void;
}

export const ModalDialog = ({show, title, children, close, onOK, onCancel}:ModalDialogProps) => {
  if (!show) {
    return null
  }

  return (
    <div className="msx-modal-backdrop">
      <div className="msx-modal msx-alert-modal">
        <div className="msx-modal-header">
          <div className="msx-modal-header-left">
            <div className="msx-modal-header-title">
              { title || 'Dialog' }
            </div>
          </div>
          <div className="msx-modal-header-right">
            <button
              type="button"
              className="msx-close-btn"
              onClick={close}
            />
          </div>
        </div>
        <div className="msx-modal-content">
          { children }
        </div>
        {/* Action buttons */}
        <div className="msx-modal-footer">
          <div className="flex justify-center items-center space-x-3">
            {/* <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-transparent py-2 px-4 text-sm font-medium text-red-400 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-offset-2"
              onClick={close}
            >
              Delete
            </button> */}
          </div>
          <div className="flex justify-center items-center space-x-3">
            { onOK && onCancel ? (
              <>
                <button
                  type="button"
                  className="msx-btn msx-btn-cancel"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="msx-btn msx-btn-primary"
                  onClick={onOK}
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="msx-btn"
                  onClick={close}
                >
                  OK
                </button>
              </>
            ) }
          </div>
        </div>
      </div>
    </div>
  )
}
