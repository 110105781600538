import { DocumentData, FirestoreDataConverter, FieldValue } from './firebaseImporter'

export type User = {
  id  : string | undefined;
  stat: {
    settings      : number;
    activeSettings: number;
  };
  email?     : string;
  stripeId?  : string;
  stripeLink?: string;
}

export const UserConverter : FirestoreDataConverter<User> = {
  toFirestore(user) {
    const data : DocumentData = {}
    if (user.stat) {
      if (user.stat instanceof FieldValue) {
        data.stat = user.stat
      }
      else {
        if (user.stat.settings) {
          data.stat = {
            ...data.stat,
            settings: user.stat.settings,
          }
        }
        if (user.stat.activeSettings) {
          data.stat = {
            ...data.stat,
            activeSettings: user.stat.activeSettings,
          }
        }
      }
    }
    return data
  },
  fromFirestore(snapshot) {
    const data = snapshot.data()
    return {
      id  : snapshot.id,
      stat: data.stat || { settings: 0, activeSettings: 0 },
      email: data.email,
      stripeId: data.stripeId,
      stripeLink: data.stripeLink,
    }
  },
}
