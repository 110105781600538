import { useState, useEffect, Fragment, useContext, useRef } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { classNames } from "../../lib"
import { SyncTargetAdminOrgUnitItem } from "../../types"
import { useFunctions } from "../../composables"
import { ProgressContext, UserContext } from "../../contexts"
import { SyncTargetEditorProps } from "."
import { updateToken } from "../../lib/updateToken"

export const SyncTargetGoogleAdminOrgUnit = ({target, onChange} : SyncTargetEditorProps) => {
  if (target.serviceId !== 'google-admin-org-unit') return null
  const { user } = useContext(UserContext)
  if (!user) return null

  const [filterWord, setFilterWord] = useState<string>('')

  const { getGoogleAdminOrgUnits } = useFunctions()
  const [orgUnits, setOrgUnits] = useState<SyncTargetAdminOrgUnitItem[]>([])
  const { showProgress } = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  useEffect(() => {
    if (!target.tokenId) return

    const tokenId = target.tokenId
    progress.current = showProgress('Loading...')

    getGoogleAdminOrgUnits({tokenId}).then(res => {
      if (res.data.status != 200 || !res.data.orgUnits) {
        console.error(res.data, tokenId)
        if (res.data.data && res.data.data.error === 'invalid_grant') {
          updateToken(user, tokenId)
        }
        else {
          alert(res.data.data?.description || res.data.statusText || 'Failed to load files.')
        }
        progress.current?.()
        return
      }
      setOrgUnits(res.data.orgUnits.map(orgUnit => ({
        id: orgUnit.orgUnitId            || null,
        name: orgUnit.name               || null,
        path: orgUnit.orgUnitPath        || null,
        description: orgUnit.description || null,
      })))
      progress.current?.()
    }).catch(err => {
      console.error(err)
      progress.current?.()
    })
  }, [target.tokenId])

  return (
    <>
      <div className="space-y-1">
        <div>
          <label htmlFor="" className="block text-sm font-medium text-gray-500">Organizational unit</label>
        </div>
        <div className="">
          <Listbox
            value={target.orgUnit}
            onChange={value => onChange({...target, orgUnit: value})}
            disabled={!target.tokenId}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    { target.orgUnit ?
                      <div className="flex items-center gap-3">
                        <span className="font-normal block truncate">
                          { target.orgUnit.name }
                        </span>
                        <span className="font-normal block truncate font-light text-gray-400">
                          { target.orgUnit.description }
                        </span>
                      </div>
                      :
                      <span className="inline-block text-gray-400">
                        Select organizational unit ...
                      </span>
                    }
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                      { 0 < orgUnits.length ? (
                        <div className="py-2 px-3">
                          <input
                            type="text"
                            className="block py-2 px-3 border w-full rounded-md border-gray-300"
                            placeholder="Search organizational unit ..."
                            defaultValue={filterWord}
                            onChange={ev => setFilterWord(ev.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="py-2 px-3">
                          <span className="block text-gray-400">
                            No org unit is found. Go to your Google Workspace dashboard and set up your first org unit.
                          </span>
                        </div>
                      ) }
                      {orgUnits
                      .filter(orgUnit => orgUnit.name?.toLowerCase().includes(filterWord.toLowerCase()))
                      .map(orgUnit => (
                        <Listbox.Option
                          key={orgUnit.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "cursor-default select-none relative py-2 pl-3 pr-9"
                            )
                          }
                          value={orgUnit}
                        >
                          {({ active }) => (
                            <>
                              <div className="flex items-center gap-3">
                                <span
                                  className={classNames(
                                    orgUnit.id == target.orgUnit?.id
                                      ? "font-semibold"
                                      : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  { orgUnit.name }
                                </span>
                                <span
                                  className={classNames(
                                    orgUnit.id == target.orgUnit?.id
                                      ? "font-normal"
                                      : "font-light",
                                    "block truncate text-gray-400"
                                  )}
                                >
                                  { orgUnit.description }
                                </span>
                                {/*
                                <div className="inline-flex items-center gap-2">
                                  <UserGroupIcon className="w-5 h-5 shrink-0 text-gray-300" />
                                  <span className="text-gray-400">
                                    { orgUnit.description }
                                  </span>
                                </div>
                                */}
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
    </>
  )
}
