import { useContext, useEffect, useRef, useState } from "react"
import { deleteApp, getApp } from "firebase/app"
import {
  Bars3Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/24/outline"

import { useNavigate, Link } from "react-router-dom"
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, QueryDocumentSnapshot, startAfter, where } from "firebase/firestore"
import { DateTime } from "luxon"

import { classNames } from '../lib'
import { productConverter, subscriptionConverter, SyncSetting, syncSettingConverter, UserConverter } from "../types"
import { SettingEditor, MainMenu, MainMenuControl, UserMenu, TargetView } from "../components"
import { ProgressContext, UserContext } from "../contexts"
import { useFirestore, useFunctions, useQuery } from "../composables"
import { FREE_SETTING_LIMIT, TRIAL_DAYS, TRIAL_PLAN } from "../constants"

export const HomePage = () => {
  const { user: authUser } = useContext(UserContext)
  const navigate = useNavigate()
  const q = useQuery()

  if (!authUser) {
    navigate('/')
    navigate(0)
    return (<></>)
  }

  const { firestore } = useFirestore()
  const { syncChanges } = useFunctions()

  const [syncSettings, setSyncSettings] = useState<SyncSetting[]>([])
  const [settingId, setSettingId] = useState<string | null | undefined>(undefined)

  const [pageLimit, _setPageLimit] = useState(20)
  const [lastSnap, setLastSnap] = useState<QueryDocumentSnapshot<SyncSetting> | undefined>(undefined)

  // const [user, setUser] = useState<User | null>(null)
  // const [subscription, setSubscription] = useState<Subscription | null>(null)
  // const [product, setProduct] = useState<Product | null>(null)
  // const [price, setPrice] = useState<Price | null>(null)

  const [limitAddition, setLimitAddition] = useState(true)

  useEffect(() => {
    if (!authUser) return
    const userRef = doc(firestore, `/Users/${authUser.uid}`).withConverter(UserConverter)

    return onSnapshot(userRef, async (userSnap) => {
      const u = userSnap.data()
      // setUser(u || null)

      try {
        const subscriptionSnap = await getDocs(query(
          collection(firestore, `/Users/${authUser.uid}/subscriptions`).withConverter(subscriptionConverter),
          where('status', 'in', ['trialing', 'active']),
        ))

        if (0 < subscriptionSnap.docs.length ) {
          const _subscription = subscriptionSnap.docs[0].data()
          const productSnap = await getDoc(_subscription.product)
          const p = productSnap.data()
          // setProduct(p || null)
          if (p) {
            setLimitAddition(
              (Number(p.metadata.settingLimit) || FREE_SETTING_LIMIT) <= (Number(u?.stat.activeSettings) || 0))
          }
          else {
            setLimitAddition(FREE_SETTING_LIMIT <= (Number(u?.stat.activeSettings) || 0))
          }
        }
        else {
          if (
            authUser.metadata.creationTime &&
            (DateTime.local() < DateTime.fromHTTP(authUser.metadata.creationTime).plus({days: TRIAL_DAYS}))
          ) {
            // Trial
            const productSnap = await getDoc(doc(firestore, TRIAL_PLAN).withConverter(productConverter))
            const p = productSnap.data()
            // setProduct(p || null)
            if (p) {
              setLimitAddition(
                (Number(p.metadata.settingLimit) || FREE_SETTING_LIMIT) <= (Number(u?.stat.activeSettings) || 0))
            }
            else {
              setLimitAddition(FREE_SETTING_LIMIT <= (Number(u?.stat.activeSettings) || 0))
            }
          }
          else {
            setLimitAddition(FREE_SETTING_LIMIT <= (Number(u?.stat.activeSettings) || 0))
          }
          return
        }
      }
      catch (err) {
        console.error(err)
      }
    })
  }, [])

  useEffect(() => {
    const unloadCallback = () => deleteApp(getApp())
    window.addEventListener("beforeunload", unloadCallback)
    return () => window.removeEventListener("beforeunload", unloadCallback)
  }, [])

  useEffect(() => {
    const ref = query(
      collection(firestore, `/Users/${authUser.uid}/SyncSettings`).withConverter(syncSettingConverter),
      orderBy('timestamp', 'desc'),
      limit(pageLimit),
    )

    const unsubscribe = onSnapshot(ref, snapshot => {
      const settings = snapshot.docs.map(d => d.data())
      setSyncSettings(settings)
      setLastSnap(
        0 < snapshot.docs.length
        ? snapshot.docs[snapshot.docs.length - 1]
        : undefined
      )
    })

    // Check if the user is redirected from the setting editor
    const state = q.get('state')
    if (state) {
      const json = JSON.parse(state)
      if (json.settingId !== undefined) {
        setSettingId(json.settingId)
      }
    }

    return unsubscribe
  }, [])

  const loadMore = () => {
    if (!lastSnap) return
    const ref = query(
      collection(firestore, `/Users/${authUser.uid}/SyncSettings`).withConverter(syncSettingConverter),
      orderBy('timestamp', 'desc'),
      startAfter(lastSnap),
      limit(pageLimit),
    )

    getDocs(ref).then(async snapshot => {
      const settings = snapshot.docs.map(d => d.data())
      setSyncSettings([...syncSettings, ...settings])
      setLastSnap(
        0 < snapshot.docs.length
        ? snapshot.docs[snapshot.docs.length - 1]
        : undefined
      )
    })
  }

  const { showProgress } = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  const procSync = (setting: SyncSetting) => {
    if (!setting.id) return
    progress.current = showProgress()
    setTimeout(() => {
      if (!setting.id) return
      syncChanges({settingId: setting.id}).then(() => {
        progress.current?.()
        progress.current = null
      }).catch((err) => {
        alert('error')
        console.error(err)
        progress.current?.()
        progress.current = null
      })

      // // update stat
      // updateSettingStatus().then((res) => {
      //   alert(JSON.stringify(res.data))
      //   progress.current?.()
      //   progress.current = null
      // }).catch((err) => {
      //   alert('error')
      //   console.error(err)
      //   progress.current?.()
      //   progress.current = null
      // })
    }, 5000)
  }

  const menu = useRef({} as MainMenuControl)
  return (
    <>
      <div>
        <MainMenu ref={menu} current="/" />

        {/* Content area */}
        <div className="md:pl-60">
          <div className="overflow-hidden max-w-full mx-auto flex flex-col md:px-6 xl:px-8">
            <div className="sticky top-0 z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 hover:bg-gray-50 active:text-gray-600 active:bg-gray-100 focus:outline-none md:hidden"
                onClick={() => menu.current.setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-4 md:px-0">
                <div className="flex-1 flex"></div>
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Profile dropdown */}
                  <UserMenu />
                </div>
              </div>
            </div>
            <main className="flex-1">
              <div className="relative max-full mx-auto px-0">
                <div className="pt-10 pb-16">
                  <div className="px-4 sm:px-6 md:px-0">
                    <h1 className="text-3xl font-extrabold text-gray-900">
                      Sync settings
                    </h1>
                  </div>
                  <div className="px-4 sm:px-6 md:px-0">
                    <div className="py-6">
                      <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto"></div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                          <button
                            type="button"
                            className="msx-btn msx-btn-primary"
                            disabled={limitAddition}
                            onClick={() => {
                              setSettingId(null)
                            }}
                          >
                            Add a new sync setting
                          </button>
                        </div>
                      </div>
                      <div className="mt-8 mb-6">
                        <table className="msx-table">
                          <thead>
                            <tr>
                              <th colSpan={4}></th>
                              <th className="!text-center !pb-0" colSpan={2}>Contacts synced in ...</th>
                              <th colSpan={1}></th>
                            </tr>
                            <tr>
                              <th scope="col">
                                Status
                              </th>
                              <th scope="col">
                                Trigger
                              </th>
                              <th scope="col"></th>
                              <th scope="col">
                                Action
                              </th>
                              <th scope="col">
                                last 7 days
                              </th>
                              <th scope="col">
                                last 365 days
                              </th>
                              <th scope="col">
                                <span className="sr-only">Actions</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {syncSettings.map((syncSetting) => (
                              <tr key={syncSetting.id}>
                                <td>
                                  <span
                                    className={classNames(
                                      syncSetting.active
                                        ? "msx-tag-success"
                                        : "msx-tag-inactive",
                                      "msx-tag"
                                    )}
                                  >
                                    {syncSetting.active
                                      ? "Active"
                                      : "Inactive"}
                                  </span>
                                </td>
                                <td>
                                  <a onClick={() => setSettingId(syncSetting.id)}>
                                    <TargetView type="src" target={syncSetting.left} />
                                  </a>
                                </td>
                                <td>
                                  <ArrowSmallRightIcon className="w-6 h-6 text-slate-500" />
                                </td>
                                <td>
                                  <a onClick={() => setSettingId(syncSetting.id)}>
                                    <TargetView type="dst" target={syncSetting.right} />
                                  </a>
                                </td>
                                <td className="!text-center">
                                  { syncSetting.stat.synced.last7days }
                                </td>
                                <td className="!text-center">
                                  { syncSetting.stat.synced.last365days }
                                </td>
                                <td>
                                  <Link to={`/log?setting=${syncSetting.id}`} className="font-normal text-sm text-indigo-600 hover:text-indigo-900">Check logs</Link>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    className="font-normal text-sm text-indigo-600 hover:text-indigo-900"
                                    onClick={() => setSettingId(syncSetting.id)}
                                  >
                                    Edit
                                  </a>
                                </td>
                                { process.env.NODE_ENV === 'development' && (
                                  <td>
                                    <a
                                      href="#"
                                      className="font-normal text-sm text-indigo-600 hover:text-indigo-900"
                                      onClick={() => procSync(syncSetting)}
                                    >
                                      Sync
                                    </a>
                                  </td>
                                ) }
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="pt-6 bg-white px-2 py-3 flex items-center justify-between border-t border-gray-200">
                          <div className="flex-1 flex justify-between sm:hidden">
                            <a
                              href="#"
                              className="relative inline-flex items-center gap-2 text-sm font-base text-gray-600"
                            >
                              <ChevronLeftIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                              Previous
                            </a>
                            <a
                              href="#"
                              className="relative inline-flex items-center gap-2 text-sm font-base text-gray-600"
                            >
                              Next
                              <ChevronRightIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                              <p className="text-sm text-gray-500">
                                Showing{" "}
                                <span className="font-medium">{ syncSettings.length }</span>{" "}
                                results
                              </p>
                            </div>
                            <div>
                              <nav
                                className="relative z-0 inline-flex items-center gap-4 -space-x-px"
                                aria-label="Pagination"
                              >
                                { lastSnap && (
                                  <a
                                    className="relative inline-flex items-center text-sm font-base text-gray-600"
                                    onClick={loadMore}
                                  >
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </a>
                                ) }
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <SettingEditor settingId={settingId} onClose={() => setSettingId(undefined)} />
    </>
  )
}
