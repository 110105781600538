import { useState, useEffect, Fragment, useContext, useRef } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { classNames } from "../../lib"
import { SyncTarget, SyncTargetCalendarItem, SyncTargetEventItem } from "../../types"
import { useFunctions } from "../../composables"
import { ProgressContext, UserContext } from "../../contexts"
import { SyncTargetEditorProps } from "."
import { updateToken } from "../../lib/updateToken"

export const SyncTargetGoogleCalendarAttendee = ({target, onChange} : SyncTargetEditorProps) => {
  if (target.serviceId !== 'google-calendar-attendee') return null
  const { user } = useContext(UserContext)
  if (!user) return null

  const [filterWordCalendar, setFilterWordCalendar] = useState<string>('')
  const [filterWordEvent, setFilterWordEvent] = useState<string>('')

  const { getGoogleCalendarCalendars, getGoogleCalendarEvents } = useFunctions()
  const [calendars, setCalendars] = useState<SyncTargetCalendarItem[]>([])
  const [events, setEvents] = useState<SyncTargetEventItem[]>([])

  const { showProgress } = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  useEffect(() => {
    if (!target.tokenId) return

    const tokenId = target.tokenId
    progress.current = showProgress('Loading...')

    getGoogleCalendarCalendars({tokenId}).then(res => {
      if (res.data.status != 200 || !res.data.calendars) {
        console.error(res.data, tokenId)
        if (res.data.data && res.data.data.error === 'invalid_grant') {
          updateToken(user, tokenId)
        }
        else {
          alert(res.data.data?.description || res.data.statusText || 'Failed to load files.')
        }
        progress.current?.()
        return
      }

      setCalendars(res.data.calendars.map(c => ({
        id: c.id || null,
        name: c.summary || null,
        description: c.description || null,
        color: c.backgroundColor || null,
      })))

      if (!target.calendar || !target.calendar.id) {
        progress.current?.()
        return
      }

      getGoogleCalendarEvents({tokenId, calendarId: target.calendar.id}).then(res => {
        if (res.data.status != 200 || !res.data.events) {
          console.error(res.data, tokenId)
          if (res.data.data && res.data.data.error === 'invalid_grant') {
            updateToken(user, tokenId)
          }
          else {
            alert(res.data.data?.description || res.data.statusText || 'Failed to load files.')
          }
          progress.current?.()
          return
        }

        setEvents(res.data.events.map(e => ({
          id: e.id || null,
          name: e.summary || null,
          description: e.description || null,
        })))
        progress.current?.()
      }).catch(err => {
        console.error(err)
        progress.current?.()
      })
    }).catch(err => {
      console.error(err)
      progress.current?.()
    })
  }, [target.tokenId])

  const onChangeCalendar = (calendar: SyncTargetCalendarItem | null) => {
    target.calendar = calendar

    if (!target.tokenId) return
    if (!calendar || !calendar.id) return
    progress.current = showProgress('Loading...')

    getGoogleCalendarEvents({tokenId: target.tokenId, calendarId: calendar.id}).then(res => {
      if (res.data.status != 200 || !res.data.events) {
        console.error(res.data, target.tokenId)
        progress.current?.()
        return
      }

      setEvents(res.data.events.map(e => ({
        id: e.id || null,
        name: e.summary || null,
        description: e.description || null,
      })))
      progress.current?.()
    }).catch(err => {
      console.error(err)
      progress.current?.()
    })
  }

  return (
    <>
      <div className="space-y-1">
        <div>
          <label htmlFor="" className="block text-sm font-medium text-gray-500">Calendar</label>
        </div>
        <div className="">
          <Listbox
            value={target.calendar}
            onChange={onChangeCalendar}
            disabled={!target.tokenId}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="msx-select w-full">
                    { target.calendar ?
                      <div className="flex items-center gap-3">
                        <span className="font-normal block truncate">
                          { target.calendar.name }
                        </span>
                        <span className="font-normal block truncate font-light text-gray-400">
                          { target.calendar.description }
                        </span>
                      </div>
                      :
                      <span className="inline-block text-gray-400">
                        Select calendar ...
                      </span>
                    }
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                      { 0 < calendars.length ? (
                        <div className="py-2 px-3">
                          <input
                            type="text"
                            className="msx-input"
                            placeholder="Search calendar ..."
                            defaultValue={filterWordCalendar}
                            onChange={ev => setFilterWordCalendar(ev.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="py-2 px-3">
                          <span className="block text-gray-400">
                            No calendar is found. Go to your Google Calendar and create your first calendar.
                          </span>
                        </div>
                      )}
                      {calendars
                      .filter(calendar => calendar.name?.toLowerCase().includes(filterWordCalendar.toLowerCase()) ||
                        calendar.description?.toLocaleLowerCase().includes(filterWordCalendar.toLowerCase())
                      )
                      .map(calendar => (
                        <Listbox.Option
                          key={calendar.id}
                          className={({ selected }) =>
                            classNames(
                              "msx-dropdown-menu-item",
                              selected || target.calendar?.id == calendar.id
                                ? "msx-dropdown-menu-item-selected"
                                : "",
                            )
                          }
                          value={calendar}
                        >
                          {({active}) => (
                            <>
                              <div className="flex flex-col text-left gap-1">
                                <div className="inline-flex items-center gap-2">
                                  <div
                                    className="inline-block h-3 w-3 rounded-sm shrink-0"
                                    style={{backgroundColor: calendar.color || "#ffffff" }}
                                  />
                                  <span
                                    className={classNames(
                                      calendar?.id == target.calendar?.id
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate"
                                    )}
                                  >
                                    { calendar.name }
                                  </span>
                                </div>
                                <div className="inline-flex items-center gap-2 pl-5">
                                  <span
                                    className={classNames(
                                      calendar.id == target.calendar?.id
                                        ? "font-normal"
                                        : "font-light",
                                      "block truncate text-sm text-gray-400"
                                    )}
                                  >
                                    { calendar.description }
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className="space-y-1">
        <div>
          <label htmlFor="" className="block text-sm font-medium text-gray-500">Event</label>
        </div>
        <div className="">
          <Listbox
            value={target.event}
            onChange={(event) => onChange({...target, event})}
            disabled={!target.tokenId}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="msx-select w-full">
                    { target.event ?
                      <div className="flex items-center gap-3">
                        <span  className="font-normal block truncate">
                          { target.event.name }
                        </span>
                        <span className="font-normal block truncate font-light text-gray-400">
                          { target.event.description }
                        </span>
                      </div>
                      :
                      <span className="inline-block text-gray-400">
                        Select event ...
                      </span>
                    }
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                      { 0 < events.length ? (
                        <div className="py-2 px-3">
                          <input
                            type="text"
                            className="msx-input"
                            placeholder="Search event ..."
                            defaultValue={filterWordEvent}
                            onChange={ev => setFilterWordEvent(ev.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="py-2 px-3">
                          <span className="block text-gray-400">
                            No event is found. Go to your Google Calendar and create your first event.
                          </span>
                        </div>
                      )}
                      {events
                      .filter(event => event.name?.toLowerCase().includes(filterWordEvent.toLowerCase()))
                      .map(event => (
                        <Listbox.Option
                          key={event.id}
                          className={({ selected }) =>
                            classNames(
                              "msx-dropdown-menu-item",
                              selected || target.event?.id == event.id
                                ? "msx-dropdown-menu-item-selected"
                                : ""
                            )
                          }
                          value={event}
                        >
                          {({active}) => (
                            <>
                              <div className="flex items-center gap-3">
                                <span
                                  className={classNames(
                                    target.event?.id == event.id
                                      ? "font-semibold"
                                      : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  { event.name }
                                </span>
                                <span
                                  className={classNames(
                                    target.event?.id == event.id
                                      ? "font-normal"
                                      : "font-light",
                                    "block truncate text-sm text-gray-400"
                                  )}
                                >
                                  { event.description }
                                </span>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
    </>
  )
}
