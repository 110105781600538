import {
  googleLogoImage, googleCalendarImage, googleDriveImage, googleSheetsImage, googleGroupsImage,
  DocumentTextIcon, FolderIcon, UserGroupIcon, CalendarDaysIcon, ClockIcon, TableCellsIcon, IdentificationIcon,
} from './icons'

import { SVGProps } from "react"

export type AccountProviderIds = 'google'
export type ServiceProviderIds = 'google-drive' | 'google-calendar' | 'google-sheets' | 'google-admin'
export type ServiceIds = 'unknown' |
  'google-drive-file' | 'google-drive-folder' |
  'google-calendar-attendee' | 'google-calendar-permission' |
  'google-sheets-sheet' | 'google-sheets-cell' |
  'google-admin-group' | 'google-admin-org-unit'

export type IconResource = ((props: SVGProps<SVGSVGElement>) => JSX.Element) |
  React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & React.RefAttributes<SVGSVGElement>>

  export type Role = {
    id         : string; // Reader, Writer, ...
    description: string; // "Can view when I am busy"
  }

  export type Service = {
  id               : ServiceIds;
  serviceProviderId: ServiceProviderIds;
  label            : string;
  icon             : IconResource | null;
  roles            : Role[];
  visible          : { src : boolean, dst : boolean };
}


export type ServiceProvider = {
  id               : ServiceProviderIds;
  accountProviderId: AccountProviderIds;
  label            : string;
  icon             : string | undefined;
}

export type AccountProvider = {
  id   : AccountProviderIds;
  label: string;
  icon : string | undefined;
}

export const AccountProviders : AccountProvider[] = [
  {
    id   : 'google',
    label: 'Google',
    icon : googleLogoImage,
  },
]

export const ServiceProviders : ServiceProvider[] = [
  {
    id               : 'google-drive',
    accountProviderId: 'google',
    label            : 'Google Drive',
    icon             : googleDriveImage,
  },
  {
    id               : 'google-calendar',
    accountProviderId: 'google',
    label            : 'Google Calendar',
    icon             : googleCalendarImage,
  },
  {
    id               : 'google-sheets',
    accountProviderId: 'google',
    label            : 'Google Sheets',
    icon             : googleSheetsImage,
  },
  {
    id               : 'google-admin',
    accountProviderId: 'google',
    label            : 'Google Workspace',
    icon             : googleGroupsImage,
  },
]

export const Services : Service[] = [
  {
    id               : 'google-drive-file',
    serviceProviderId: 'google-drive',
    label            : "Persons with access to a certain file",
    icon             : DocumentTextIcon,
    roles            : [
      { id: 'reader', description: "Reader" },
      { id: 'writer', description: "Writer" },
    ],
    visible           : { src: true, dst: true },
  },
  {
    id               : 'google-drive-folder',
    serviceProviderId: 'google-drive',
    label            : "Persons with access to a certain folder",
    icon             : FolderIcon,
    roles            : [
      { id: 'reader', description: "Reader" },
      { id: 'writer', description: "Writer" },
    ],
    visible          : { src: true, dst: true },
  },
  {
    id               : 'google-calendar-attendee',
    serviceProviderId: 'google-calendar',
    label            : "Guests of a certain event",
    icon             : ClockIcon,
    roles            : [],
    visible          : { src: true, dst: true },
  },
  {
    id               : 'google-calendar-permission',
    serviceProviderId: 'google-calendar',
    label            : "Persons with access to a certain calendar",
    icon             : CalendarDaysIcon,
    roles            : [
      { id: 'freeBusyReader', description: 'freeBusyReader' },
      { id: 'Reader', description: "Reader" },
      { id: 'Writer', description: "Writer" },
    ],
    visible          : { src: true, dst: true },
  },
  {
    id               : 'google-sheets-cell',
    serviceProviderId: 'google-sheets',
    label            : "Rows in a certain spreadsheet",
    icon             : TableCellsIcon,
    roles            : [],
    visible          : { src: true, dst: false },
  },
  {
    id               : 'google-admin-group',
    serviceProviderId: 'google-admin',
    label            : "Members of a certain group",
    icon             : UserGroupIcon,
    roles            : [
      { id: 'MEMBER', description: 'Member' },
      { id: 'MANAGER', description: "Manager" },
    ],
    visible          : { src: false, dst: true },
  },
  {
    id               : 'google-admin-org-unit',
    serviceProviderId: 'google-admin',
    label            : "Members of a certain organizational unit",
    icon             : IdentificationIcon,
    roles            : [],
    visible          : { src: false, dst: true },
  },
]

const ServiceIdToService = Object.fromEntries(Services.map(service => [service.id, service]))

export const getService = (id: ServiceIds) : Service | null => {
  return ServiceIdToService[id] || null
}

export const getServiceRole = (serviceId: ServiceIds, roleId: string) : Role | null => {
  const service = getService(serviceId)
  if (!service) {
    return null
  }
  for (const role of service.roles) {
    if (role.id === roleId) {
      return role
    }
  }
  return null
}

const ServiceProviderIdToServiceProvider = Object.fromEntries(ServiceProviders.map(provider => [provider.id, provider]))

export const getServiceProvider = (id: ServiceProviderIds) : ServiceProvider | null => {
  return ServiceProviderIdToServiceProvider[id] || null
}

export const getServiceProviderFromServiceId = (id: ServiceIds) : ServiceProvider | null => {
  const service = getService(id)
  if (!service) {
    return null
  }
  return getServiceProvider(service.serviceProviderId)
}

const AccountProviderIdToAccountProvider = Object.fromEntries(AccountProviders.map(provider => [provider.id, provider]))

export const getAccountProvider = (id: AccountProviderIds) : AccountProvider | null => {
  return AccountProviderIdToAccountProvider[id] || null
}

export const getAccountProviderFromServiceId = (id: ServiceIds) : AccountProvider | null => {
  const service = getService(id)
  if (!service) {
    return null
  }

  return getAccountProviderFromServiceProviderId(service.serviceProviderId)
}

export const getAccountProviderFromServiceProviderId = (id: ServiceProviderIds) : AccountProvider | null => {
  const provider = getServiceProvider(id)
  if (!provider) {
    return null
  }

  return getAccountProvider(provider.accountProviderId)
}

export const hasRole = (id: ServiceIds, roleId: string) : boolean => {
  const service = getService(id)
  if (!service) {
    return false
  }
  for (const role of service.roles) {
    if (role.id === roleId) {
      return true
    }
  }
  return false
}
