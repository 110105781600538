import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot } from "./firebaseImporter"

export type Price = {
  id         : string;
  active     : boolean;
  billing_scheme: 'per_unit' | 'tiered';
  currency   : string;
  description: string | null;
  interval   : string;
  metadata   : unknown;
  product    : string;
  recurring  : {
    aggregate_usage  : string | null;
    interval         : string;
    interval_count   : number;
    trial_period_days: number | null;
    usage_type       : 'licensed' | 'metered';
  };
  tax_behavior: 'exclusive' | 'inclusive' | 'unspecified';
  tiers       : null;
  tiers_mode  : 'graduated' | 'volume' | null;
  transform_quantity: null;
  trial_period_days: number | null;
  type        : 'recurring' | 'one_time';
  unit_amount : number;
}

export const priceConverter : FirestoreDataConverter<Price> = {
  toFirestore(price: Price) : DocumentData {
    return {
      active     : price.active,
      billing_scheme: price.billing_scheme,
      currency   : price.currency,
      description: price.description,
      interval   : price.interval,
      metadata   : price.metadata,
      product    : price.product,
      recurring  : {
        aggregate_usage  : price.recurring.aggregate_usage,
        interval         : price.recurring.interval,
        interval_count   : price.recurring.interval_count,
        trial_period_days: price.recurring.trial_period_days,
        usage_type       : price.recurring.usage_type,
      },
      tax_behavior: price.tax_behavior,
      tiers       : price.tiers,
      tiers_mode  : price.tiers_mode,
      transform_quantity: price.transform_quantity,
      trial_period_days: price.trial_period_days,
      type        : price.type,
      unit_amount : price.unit_amount,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot) : Price {
    const price = snapshot.data()
    return {
      id         : snapshot.id,
      active     : price.active,
      billing_scheme: price.billing_scheme,
      currency   : price.currency,
      description: price.description,
      interval   : price.interval,
      metadata   : price.metadata,
      product    : price.product,
      recurring  : {
        aggregate_usage  : price.recurring.aggregate_usage,
        interval         : price.recurring.interval,
        interval_count   : price.recurring.interval_count,
        trial_period_days: price.recurring.trial_period_days,
        usage_type       : price.recurring.usage_type,
      },
      tax_behavior: price.tax_behavior,
      tiers       : price.tiers,
      tiers_mode  : price.tiers_mode,
      transform_quantity: price.transform_quantity,
      trial_period_days: price.trial_period_days,
      type        : price.type,
      unit_amount : price.unit_amount,
    }
  },
}
