import { PROJECT_ID } from "./credentials"

// Free Plan
export const FREE_SETTING_LIMIT = 2
export const FREE_EVENT_LIMIT = 100

export const TRIAL_DAYS = 15
export const TRIAL_PLAN = PROJECT_ID === 'membersync-prod'
  ? '/Products/prod_OwGuzaPs12pZHp' // Standard Plan - Production Environment
  : '/Products/prod_OoSW3YCMyoxFMq' // Standard Plan - Test Environment
