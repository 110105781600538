import { DocumentData, DocumentReference, FirestoreDataConverter, QueryDocumentSnapshot } from "./firebaseImporter"
import { DateTime } from "luxon"
import { Price } from "./price"
import { Product } from "./product"

export type SubscriptionItem = {
  billing_thresholds: null;
  created  : number;
  id       : string;
  metadata : unknown;
  object   : 'subscription_item';
  plan     : {
    active     : boolean;
    aggregate_usage: string | null;
    amount     : number;
    amount_decimal: string;
    billing_scheme: 'per_unit' | 'tiered';
    created    : number;
    currency   : string;
    id         : string;
    interval   : string;
    interval_count: number;
    livemode   : boolean;
    metadata   : unknown;
    nickname   : string | null;
    object     : 'plan';
    product    : string;
    tiers_mode: 'graduated' | 'volume' | null;
    transform_usage: null;
    trial_period_days: number | null;
    usage_type : 'licensed' | 'metered';
  };
  price    : {
    active     : boolean;
    billing_scheme: 'per_unit' | 'tiered';
    created    : number;
    currency   : string;
    custom_unit_amount: string | null;
    id         : string;
    livemode   : boolean;
    lookup_key : string | null;
    metadata   : unknown;
    nickname   : string | null;
    object     : 'price';
    product    : {
      active     : boolean;
      attributes : string[];
      created    : boolean;
      default_price: string;
      description: string;
      id         : string;
      images     : string[];
      livemode   : boolean;
      metadata   : unknown;
      name       : string;
      object     : 'product';
      package_dimensions: null;
      shippable  : boolean | null;
      statement_descriptor: string | null;
      tax_code   : string | null;
      type       : 'good' | 'service';
      unit_label : string | null;
      updated    : number;
      url        : string | null;
    };
    recurring  : {
      aggregate_usage  : string | null;
      interval         : string;
      interval_count   : number;
      trial_period_days: number | null;
      usage_type       : 'licensed' | 'metered';
    };
    tax_behavior     : 'exclusive' | 'inclusive' | 'unspecified';
    tiers_mode       : 'graduated' | 'volume' | null;
    transform_quantity: null;
    type             : 'recurring' | 'one_time';
    unit_amount      : number;
    unit_amount_decimal: string;
  };
  quantity : number;
  subscription: string;
  tax_rates: string[];
}

export type Subscription = {
  id         : string;
  cancel_at  : DateTime | null;
  cancel_at_period_end: boolean;
  canceled_at: DateTime | null;
  created    : DateTime;
  current_period_end: DateTime;
  current_period_start: DateTime;
  ended_at   : DateTime | null;
  items      : SubscriptionItem[];
  metadata   : { [key: string]: string };
  price      : DocumentReference<Price>;
  prices     : DocumentReference<Price>[];
  product    : DocumentReference<Product>;
  quantity   : number;
  role       : string | null;
  status     : 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'trialing' | 'unpaid';
  stripeLink : string;
  trial_end  : DateTime | null;
  trial_start: DateTime | null;
}

export const subscriptionConverter : FirestoreDataConverter<Subscription> = {
  toFirestore(subscription: Subscription) : DocumentData {
    return {
      cancel_at  : subscription.cancel_at ? subscription.cancel_at.toJSDate() : null,
      cancel_at_period_end: subscription.cancel_at_period_end,
      canceled_at: subscription.canceled_at ? subscription.canceled_at.toJSDate() : null,
      created    : subscription.created.toJSDate(),
      current_period_end: subscription.current_period_end.toJSDate(),
      current_period_start: subscription.current_period_start.toJSDate(),
      ended_at   : subscription.ended_at ? subscription.ended_at.toJSDate() : null,
      items      : subscription.items,
      metadata   : subscription.metadata,
      price      : subscription.price,
      prices     : subscription.prices,
      product    : subscription.product,
      quantity   : subscription.quantity,
      role       : subscription.role,
      status     : subscription.status,
      stripeLink : subscription.stripeLink,
      trial_end  : subscription.trial_end ? subscription.trial_end.toJSDate() : null,
      trial_start: subscription.trial_start ? subscription.trial_start.toJSDate() : null,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot) : Subscription {
    const subscription = snapshot.data()
    return {
      id         : snapshot.id,
      cancel_at  : subscription.cancel_at ? DateTime.fromJSDate(subscription.cancel_at.toDate()) : null,
      cancel_at_period_end: subscription.cancel_at_period_end,
      canceled_at: subscription.canceled_at ? DateTime.fromJSDate(subscription.canceled_at.toDate()) : null,
      created    : DateTime.fromJSDate(subscription.created.toDate()),
      current_period_end: DateTime.fromJSDate(subscription.current_period_end.toDate()),
      current_period_start: DateTime.fromJSDate(subscription.current_period_start.toDate()),
      ended_at   : subscription.ended_at ? DateTime.fromJSDate(subscription.ended_at.toDate()) : null,
      items      : Array.isArray(subscription.items) ? subscription.items : [],
      metadata   : subscription.metadata,
      price      : subscription.price,
      prices     : subscription.prices,
      product    : subscription.product,
      quantity   : subscription.quantity,
      role       : subscription.role,
      status     : subscription.status,
      stripeLink : subscription.stripeLink,
      trial_end  : subscription.trial_end ? DateTime.fromJSDate(subscription.trial_end.toDate()) : null,
      trial_start: subscription.trial_start ? DateTime.fromJSDate(subscription.trial_start.toDate()) : null,
    }
  },
}
