import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot } from "./firebaseImporter"

export type Product = {
  id         : string;
  active     : boolean;
  description: string | null;
  images     : string[];
  metadata   : { [key: string]: string };
  name       : string;
  role       : string | null;
  tax_code   : string | null;
}

export const productConverter : FirestoreDataConverter<Product> = {
  toFirestore(product: Product) : DocumentData {
    return {
      active     : product.active,
      description: product.description,
      images     : product.images,
      metadata   : product.metadata,
      name       : product.name,
      role       : product.role,
      tax_code   : product.tax_code,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot) : Product {
    const product = snapshot.data()
    return {
      id         : snapshot.id,
      active     : product.active,
      description: product.description || null,
      images     : product.images      || [],
      metadata   : product.metadata    || {},
      name       : product.name        || '',
      role       : product.role,
      tax_code   : product.tax_code,
    }
  },
}
