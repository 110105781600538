import React, { createContext } from 'react'

export type ProgressStateItem = {
  progress: string | React.ReactNode;
  style?  : React.CSSProperties;
}

export type ProgressFunction = (update? : string | ProgressStateItem) => void;

export type ProgressContextProps = {
  showProgress : (_?: string | ProgressStateItem) => ProgressFunction;
  clearProgress: () => void;
}

const InitialState = {
  showProgress : (_?: string | ProgressStateItem) => () => { return }, // stub
  clearProgress: () => { return }, // stub
}

export const ProgressContext = createContext<ProgressContextProps>(InitialState)
