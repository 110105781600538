import { useContext, useEffect, useRef, useState, Fragment, useCallback } from "react"
import { Menu, Transition } from "@headlessui/react"
import {
  Bars3Icon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline"
import { classNames } from "../lib"
import { MainMenu, MainMenuControl, UserMenu, PlanSelector, ModalDialog } from "../components"
import { ProgressContext, UserContext } from "../contexts"
import { useFirestore, useFunctions, useQuery } from "../composables"
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, where, writeBatch } from "firebase/firestore"
import { getServiceProvider, Price, Product, productConverter, ServiceToken, Subscription, subscriptionConverter, SyncSetting, syncSettingConverter, tokenConverter } from "../types"
import { getAuth, GoogleAuthProvider, reauthenticateWithCredential, signInWithPopup } from "firebase/auth"
import { FirebaseError } from "firebase/app"
import { FREE_EVENT_LIMIT, FREE_SETTING_LIMIT, TRIAL_DAYS, TRIAL_PLAN } from "../constants"
import { DateTime } from "luxon"
import { updateToken } from "../lib/updateToken"

const GeneralContent = () => {
  const { user } = useContext(UserContext)
  if (!user) return null

  const [dialog, setDialog] = useState<string | null>(null)
  const confirmDeleteAccount = () => {
    setDialog('This action is irreversible. Are you sure?')
  }

  const { firestore } = useFirestore()
  const {showProgress} = useContext(ProgressContext)

  const deleteAccount = async () => {
    showProgress({progress:'Deleting account...'})
    try {
      // Reauth
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      const result = await signInWithPopup(auth, provider)
      const credential = GoogleAuthProvider.credentialFromResult(result)

      if (!credential) throw new Error('Credential is null.')

      await reauthenticateWithCredential(user, credential)

      const batch = writeBatch(firestore)

      // Logs
      const logs = await getDocs(collection(firestore, `/Users/${user.uid}/Logs`))
      logs.forEach(log => batch.delete(log.ref))

      // SyncSettings
      const settings = await getDocs(collection(firestore, `/Users/${user.uid}/SyncSettings`))
      for (const setting of settings.docs) {
        // Related Channels
        const channels = await getDocs(
          query(
            collection(firestore, `/Channels`),
            where('settingId', '==', setting.id),
          )
        )
        channels.forEach(channel => batch.delete(channel.ref))
        batch.delete(setting.ref)
      }

      // Tokens
      const tokens = await getDocs(collection(firestore, `/Users/${user.uid}/Tokens`))
      tokens.forEach(token => batch.delete(token.ref))

      // checkout_sessions
      const sessions = await getDocs(collection(firestore, `/Users/${user.uid}/checkout_sessions`))
      sessions.forEach(session => batch.delete(session.ref))

      // User
      batch.delete(doc(firestore, `/Users/${user.uid}`))

      await batch.commit()
      await user.delete()

      setDialog(null)

      location.href = '/'
    }
    catch (err) {
      if (err instanceof FirebaseError) {
        console.error(err)
        alert(err)
        setDialog(null)
      }
      else {
        console.error(err)
        alert(err)
        setDialog(null)
      }
    }
  }

  const openGoogle = () => {
    // window.open('https://myaccount.google.com/', '_blank')
    location.href = 'https://myaccount.google.com/'
  }

  return (
    <>
      <ModalDialog
        show={dialog !== null}
        title="Warning"
        close={() => setDialog(null)}
        onCancel={() => setDialog(null)}
        onOK={deleteAccount}
      >
        <div>{ dialog }</div>
      </ModalDialog>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Profile
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Manage my account credential.
          </p>
        </div>
        <div className="mt-6">
          <div className="msx-setting-rows">
            <div className="msx-setting-row">
              <label>Name</label>
              <div className="msx-setting-row-items">
                <div className="msx-setting-row-item">
                  {user.displayName}
                </div>
                <div className="msx-setting-row-item">
                  <button
                    type="button"
                    className="msx-text-btn"
                    onClick={openGoogle}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
            <div className="msx-setting-row">
              <label>Email</label>
              <div className="msx-setting-row-items">
                <div className="msx-setting-row-item">
                  {user.email}
                </div>
                <div className="msx-setting-row-item">
                  <button
                    type="button"
                    className="msx-text-btn"
                    onClick={openGoogle}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
            <div className="msx-setting-row">
              <label>Password</label>
              <div className="msx-setting-row-items">
                <div className="msx-setting-row-item">
                  <button
                    type="button"
                    className="msx-text-btn"
                    onClick={openGoogle}
                  >
                    Change password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Advanced settings
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Manage my account settings and configurations.
          </p>
        </div>
        <div className="mt-6">
          <div className="msx-setting-rows">
            <div className="msx-setting-row">
              <label>Language</label>
              <div className="msx-setting-row-items">
                <div className="msx-setting-row-item">
                  <select className="msx-select">
                    <option>English</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="msx-setting-row">
              <label>Timezone</label>
              <div className="msx-setting-row-items">
                <div className="msx-setting-row-item">
                  <select className="msx-select">
                    <option>
                      America/Los_Angeles (UCT-08:00)
                    </option>
                    {/* Show a list of available timezones */}
                  </select>
                </div>
              </div>
            </div>
            <div className="msx-setting-row">
              <label>Delete account</label>
              <div className="msx-setting-row-items">
                <div className="msx-setting-row-item">
                  <button
                    type="button"
                    className="msx-text-btn"
                    onClick={confirmDeleteAccount}
                  >
                    Delete account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const countSettings = (token: ServiceToken, settings: SyncSetting[]) : number => {
  let count = 0
  for (const setting of settings) {
    if (setting.left.tokenId === token.id || setting.right.tokenId === token.id) {
      count++
    }
  }
  return count
}

const IntegrationsContent = () => {
  const { user } = useContext(UserContext)
  if (!user) return null

  const [tokens, setTokens] = useState<ServiceToken[]>([])
  const [settings, setSettings] = useState<SyncSetting[]>([])

  const { firestore } = useFirestore()
  useEffect(() => {
    const tokensRef = collection(firestore, `/Users/${user.uid}/Tokens`).withConverter(tokenConverter)
    const unsubscribeTokens = onSnapshot(tokensRef, snapshot => setTokens(snapshot.docs.map(doc => doc.data())))

    const settingsRef = collection(firestore, `/Users/${user.uid}/SyncSettings`).withConverter(syncSettingConverter)
    const unsubscribeSettings = onSnapshot(settingsRef, snapshot => setSettings(snapshot.docs.map(doc => doc.data())))

    return () => {
      unsubscribeTokens()
      unsubscribeSettings()
    }
  }, [])

  const reconnectToken = (token: ServiceToken) => {
    if (token.id) updateToken(user, token.id).catch(console.error)
  }

  const deleteToken = (token: ServiceToken) => {
    deleteDoc(doc(firestore, `/Users/${user.uid}/Tokens/${token.id}`)).catch(console.error)
  }

  return (
    <>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Integrations
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Manage my authorized integrations.
          </p>
        </div>
        <div className="mt-6">
          <div className="msx-setting-rows">
            <div className="msx-setting-row">
              <label>Currently authorized</label>
              <div className="msx-setting-row-items">
                <div className="msx-setting-row-item">
                  {tokens.map(token => {
                    const count = countSettings(token, settings)
                    return (
                      <div className="rounded border w-full inline-flex items-center gap-4 px-4 py-3">
                        <img
                          className={classNames(
                            0 < count ? "" : "opacity-40",
                            "w-5",
                          )}
                          src={getServiceProvider(token.providerId)?.icon}
                        />
                        <div className="inline-flex flex-col grow">
                          <p
                            className={classNames(
                              0 < count ? "text-gray-700" : "text-gray-400",
                              "text-sm",
                            )}
                          >
                            { getServiceProvider(token.providerId)?.label }
                          </p>
                          <p
                            className={classNames(
                              0 < count ? "text-gray-400" : "text-gray-300",
                              "text-xs inline-flex items-center gap-2",
                            )}
                          >
                            <span>{token.info.email}</span>
                            <span>&middot;</span>
                            <span>
                              {count}{" "}
                              sync setting
                              {1 < count ? "s" : ""}
                            </span>
                          </p>
                        </div>
                        <Menu
                          as="div"
                          className="flex-shrink-0 relative ml-4"
                        >
                          <div>
                            <Menu.Button className="bg-white flex">
                              <span className="sr-only">
                                Open menu
                              </span>
                              <EllipsisHorizontalIcon className="w-5 text-gray-400" />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="msx-dropdown-menu z-10 origin-top-right absolute right-0 mt-2 w-48">
                              <Menu.Item>
                                <a
                                  className="msx-dropdown-menu-item"
                                  onClick={() => reconnectToken(token)}
                                >Reconnect</a>
                              </Menu.Item>
                              <Menu.Item>
                                <a
                                  className="msx-dropdown-menu-item"
                                  onClick={() => deleteToken(token)}
                                >Delete</a>
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const BillingContent = () => {
  const { user } = useContext(UserContext)
  if (!user) return null

  const { firestore } = useFirestore()
  const { createPortalLink } = useFunctions()

  const [subscription, setSubscription] = useState<Subscription | null>(null)
  const [product, setProduct] = useState<Product | null>(null)
  const [price, setPrice] = useState<Price | null>(null)

  const [eventLimit, setEventLimit] = useState(FREE_EVENT_LIMIT)
  const [settingLimit, setSettingLimit] = useState(FREE_SETTING_LIMIT)
  const [trial, setTrial] = useState(false)

  const [showPlanSelector, setShowPlanSelector] = useState(false)
  const {showProgress} = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  const openCustomerPortal = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault()
    progress.current = showProgress('Preparation in progress...')
    try {
      const { data } = await createPortalLink({
        returnUrl: window.location.origin,
      })
      window.location.assign(data.url)
      progress.current?.()
    }
    catch (err: unknown) {
      console.error(err)
      alert('Failed to open customer portal')
      progress.current?.()
    }
  }

  useEffect(() => {
    const subscriptionsRef = query(
      collection(firestore, `/Users/${user.uid}/subscriptions`).withConverter(subscriptionConverter),
      where('status', 'in', ['trialing', 'active']),
    )

    return onSnapshot(subscriptionsRef, subscriptionSnap => {
      if (0 < subscriptionSnap.docs.length ) {
        const _subscription = subscriptionSnap.docs[0].data()
        setSubscription(_subscription)
        getDoc(_subscription.price).then(snapshot => {
          setPrice(snapshot.data() || null)
        }).catch(console.error)

        getDoc(_subscription.product).then(snapshot => {
          const _product = snapshot.data()
          setProduct(snapshot.data() || null)

          if (_product?.metadata.eventLimit) {
            setEventLimit(Number(_product.metadata.eventLimit))
          }

          if (_product?.metadata.settingLimit) {
            setSettingLimit(Number(_product.metadata.settingLimit))
          }
        }).catch(console.error)
      }
      else {
        setSubscription(null)
        setPrice(null)

        if (
          user.metadata.creationTime &&
          (DateTime.local() < DateTime.fromHTTP(user.metadata.creationTime).plus({days: TRIAL_DAYS}))
        ) {
          // Trial
          setTrial(true)
          getDoc(doc(firestore, TRIAL_PLAN).withConverter(productConverter)).then(snapshot => {
            const _product = snapshot.data()
            setProduct(snapshot.data() || null)

            if (_product?.metadata.eventLimit) {
              setEventLimit(Number(_product.metadata.eventLimit))
            }

            if (_product?.metadata.settingLimit) {
              setSettingLimit(Number(_product.metadata.settingLimit))
            }
          }).catch(err => console.error('trial', err))
        }
        else {
          // Free Plan
          setEventLimit(FREE_EVENT_LIMIT)
          setSettingLimit(FREE_SETTING_LIMIT)
        }
      }
    })
  }, [])

  return (
    <>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Plan & billing
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Manage my billing information.
          </p>
        </div>
        <div className="mt-6">
          <div className="msx-setting-rows">
            { subscription ? (
              <>
                <div className="msx-setting-row">
                  <label>Current plan</label>
                  <div className="msx-setting-row-items">
                    <div className="msx-setting-row-item">
                      { product?.name }
                      &nbsp;( {
                        price
                        ? `${price.unit_amount / 100} ${price.currency} / ${price.interval}`
                        : ''
                        } )
                    </div>
                    <div className="msx-setting-row-item">
                      <button
                        type="button"
                        className="msx-text-btn"
                        onClick={openCustomerPortal}
                      >
                        View billing dashboard
                      </button>
                    </div>
                  </div>
                </div>

                <div className="msx-setting-row">
                  <label>Setting limit</label>
                  <div className="msx-setting-row-items">
                    <div className="msx-setting-row-item">
                      { settingLimit } settings
                    </div>
                  </div>
                </div>
                <div className="msx-setting-row">
                  <label>Event limit</label>
                  <div className="msx-setting-row-items">
                    <div className="msx-setting-row-item">
                      { eventLimit } events / setting
                    </div>
                  </div>
                </div>

                <div className="msx-setting-row">
                  <label>Next billing schedule</label>
                  <div className="msx-setting-row-items">
                    <div className="msx-setting-row-item">
                      {subscription.current_period_end.toJSDate().toLocaleDateString()}
                    </div>
                    <div className="msx-setting-row-item">
                      <button
                        type="button"
                        className="msx-text-btn"
                        onClick={openCustomerPortal}
                      >
                        Check history
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="msx-setting-row">
                  <label>Current plan</label>
                  <div className="msx-setting-row-items">
                    <div className="msx-setting-row-item">
                      { trial ? 'Free Trial' : 'Free' }
                    </div>
                    <div className="msx-setting-row-item">
                      <button
                        type="button"
                        className="msx-text-btn font-bold"
                        onClick={() => setShowPlanSelector(true)}
                      >
                        Upgrade now
                      </button>
                    </div>
                    <div className="msx-setting-row-item">
                      <button
                        type="button"
                        className="msx-text-btn"
                        onClick={openCustomerPortal}
                      >
                        Customer portal
                      </button>
                    </div>
                  </div>
                </div>
                <div className="msx-setting-row">
                  <label>Setting limit</label>
                  <div className="msx-setting-row-items">
                    <div className="msx-setting-row-item">
                      { settingLimit } settings
                    </div>
                  </div>
                </div>
                <div className="msx-setting-row">
                  <label>Event limit</label>
                  <div className="msx-setting-row-items">
                    <div className="msx-setting-row-item">
                      { eventLimit } events / setting
                    </div>
                  </div>
                </div>
              </>
            ) }
          </div>
        </div>
      </div>
      <PlanSelector show={showPlanSelector} onClose={() => setShowPlanSelector(false)} />
    </>
  )
}

const tabs = [
  {
    id     : 'general',
    name   : 'General',
    content: (<GeneralContent />),
  },
  {
    id     : 'integrations',
    name   : 'Integrations',
    content: (<IntegrationsContent />),
  },
  {
    id     : 'billing',
    name   : 'Plan & billing',
    content: (<BillingContent />),
  },
]

export const AccountPage = () => {
  const q = useQuery()
  const [selectedTab, setSelectedTab] = useState(q.get('tab') || 'general')
  const menu = useRef({} as MainMenuControl)
  return (
    <>
      <div>
        <MainMenu ref={menu} current="/account" />

        {/* Content area */}
        <div className="md:pl-64">
          <div className="max-w-full mx-auto flex flex-col md:px-6 xl:px-8">
            <div className="sticky top-0 z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => menu.current.setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-4 md:px-0">
                <div className="flex-1 flex"></div>
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Profile dropdown */}
                  <UserMenu />
                </div>
              </div>
            </div>
            <main className="flex-1">
              <div className="relative max-w-full mx-auto px-0">
                <div className="pt-10 pb-16">
                  <div className="px-4 sm:px-6 md:px-0">
                    <h1 className="text-3xl font-extrabold text-gray-900">
                      My account
                    </h1>
                  </div>
                  <div className="px-4 sm:px-6 md:px-0">
                    <div className="py-6">
                      {/* Tabs */}
                      <div className="lg:hidden">
                        <label htmlFor="selected-tab" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="selected-tab"
                          name="selected-tab"
                          className="msx-select"
                          defaultValue={selectedTab}
                          onChange={ev => setSelectedTab(ev.target.value)}
                        >
                          {tabs.map((tab) => (
                            <option key={tab.name} value={tab.id}>{tab.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="hidden lg:block">
                        <nav className="msx-tabs">
                          {tabs.map((tab) => (
                            <a
                              key={tab.name}
                              onClick={() => setSelectedTab(tab.id)}
                              className={classNames(
                                "msx-tab",
                                tab.id === selectedTab ? "msx-tab-selected" : ""
                              )}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                      {/* Description list with inline editing */}
                      { tabs.filter(tab => tab.id === selectedTab)[0].content }
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
