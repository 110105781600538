import { useState, useEffect, Fragment, useContext, useRef } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { classNames } from "../../lib"
import { SyncTargetCalendarItem, getService } from "../../types"
import { useFunctions } from "../../composables"
import { ProgressContext, UserContext } from "../../contexts"
import { SyncTargetEditorProps } from "."
import { updateToken } from "../../lib/updateToken"

export const SyncTargetGoogleCalendarPermission = ({type, target, onChange} : SyncTargetEditorProps) => {
  if (target.serviceId !== 'google-calendar-permission') return null
  const { user } = useContext(UserContext)
  if (!user) return null

  const [filterWord, setFilterWord] = useState<string>('')

  const { getGoogleCalendarCalendars } = useFunctions()
  const [calendars, setCalendars] = useState<SyncTargetCalendarItem[]>([])

  const permissions = getService('google-calendar-permission')?.roles || [] // Get predefined permissions

  const { showProgress } = useContext(ProgressContext)
  const progress = useRef<(()=>void) | null>(null)

  useEffect(() => {
    if (!target.tokenId) return

    const tokenId = target.tokenId
    progress.current = showProgress('Loading...')

    getGoogleCalendarCalendars({tokenId}).then(res => {
      if (res.data.status != 200 || !res.data.calendars) {
        console.error(res.data, tokenId)
        if (res.data.data && res.data.data.error === 'invalid_grant') {
          updateToken(user, tokenId)
        }
        else {
          alert(res.data.data?.description || res.data.statusText || 'Failed to load files.')
        }
        progress.current?.()
        return
      }

      const calendars = res.data.calendars.map(cal => ({
        id: cal.id || null,
        name: cal.summary || null,
        description: cal.description || null,
        color: cal.backgroundColor || null,
      } as SyncTargetCalendarItem))
      setCalendars(calendars)
      progress.current?.()
    })
  }, [target.tokenId])

  return (
    <>
      <div className="space-y-1">
        <div>
          <label htmlFor="" className="block text-sm font-medium text-gray-500">Calendar</label>
        </div>
        <div className="">
          <Listbox
            value={target.calendar}
            onChange={(calendar) => onChange({...target, calendar})}
            disabled={!target.tokenId}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="msx-select w-full">
                    { target.calendar ?
                      <div className="flex items-center gap-3">
                        <span className="font-normal block truncate">
                          { target.calendar?.name }
                        </span>
                        <span className="font-normal block truncate font-light text-gray-400">
                          { target.calendar?.description }
                        </span>
                      </div>
                      :
                      <span className="inline-block text-gray-400">
                        Select calendar ...
                      </span>
                    }
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-px w-full max-h-60 msx-dropdown-menu">
                      { 0 < calendars.length ? (
                        <div className="py-2 px-3">
                          <input
                            type="text"
                            className="msx-input"
                            placeholder="Search calendar ..."
                            defaultValue={filterWord}
                            onChange={ev => setFilterWord(ev.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="py-2 px-3">
                          <span className="block text-gray-400">
                            No calendar is found. Go to your Google Calendar and create your first calendar.
                          </span>
                        </div>
                      )}

                      {calendars
                      .filter(calendar => calendar.name?.toLowerCase().includes(filterWord.toLowerCase()) ||
                      calendar.description?.toLocaleLowerCase().includes(filterWord.toLowerCase())
                    )
                      .map(calendar => (
                        <Listbox.Option
                          key={calendar.id}
                          className={({ active }) =>
                            classNames(
                              "msx-dropdown-menu-item",
                              active
                                ? "msx-dropdown-menu-item"
                                : ""
                            )
                          }
                          value={calendar}
                        >
                          <div className="flex flex-col text-left gap-1">
                            <div className="inline-flex items-center gap-2">
                              <span className="inline-block h-3 w-3 rounded-sm shrink-0" style={{backgroundColor: calendar.color || "#ffffff" }}></span>
                              <span
                                className={classNames(
                                  calendar?.id == target.calendar?.id
                                    ? "font-semibold"
                                    : "font-normal",
                                  "block truncate"
                                )}
                              >
                                { calendar.name }
                              </span>
                            </div>
                            <div className="inline-flex items-center gap-2 pl-5">
                              <span
                                className={classNames(
                                  calendar.id == target.calendar?.id
                                    ? "font-normal"
                                    : "font-light",
                                  "block truncate text-sm text-gray-400"
                                )}
                              >
                                { calendar.description }
                              </span>
                            </div>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      {type == 'dst' && (
        <>
          <div className="space-y-1">
            <div>
              <label htmlFor="" className="block text-sm font-medium text-gray-500">Default permission when added</label>
            </div>
            <div>
              <Listbox
                value={target.defaultRoleId}
                onChange={value => onChange({...target, defaultRoleId: value})} // Update the selected permission state when a new permission is selected
                disabled={!target.tokenId}
              >
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button className="msx-select w-full">
                        {target.defaultRoleId ? (
                          <span className="block truncate">
                            {target.defaultRoleId}
                          </span>
                        ) : (
                          <span className="block text-gray-400 truncate">
                            Select default permission ...
                          </span>
                        )}
                      </Listbox.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-px w-full msx-dropdown-menu">
                          {permissions.map(permission => (
                            <Listbox.Option
                              key={permission.id}
                              className={({ selected }) =>
                                classNames(
                                  "msx-dropdown-menu-item",
                                  selected ? "msx-dropdown-menu-item-selected" : ""
                                )
                              }
                              value={permission.id}
                            >
                              {permission.description}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </>
      )}
    </>
  )
}
