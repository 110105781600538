import { getApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import {
  // ReqGenerateGoogleDriveAuthUrl, ResGenerateGoogleDriveAuthUrl,
  // ReqSetGoogleDriveToken, ResSetGoogleDriveToken,
  // ReqGetGoogleDriveToken, ResGetGoogleDriveToken,
  ReqGetGoogleDriveFiles, ResGetGoogleDriveFiles,
  ReqSyncChanges, ResSyncChanges,
  ReqGetGoogleCalendarCalendars, ResGetGoogleCalendarCalendars,
  ReqGetGoogleCalendarEvents, ResGetGoogleCalendarEvents,
  ReqGetGoogleSheetsSheet, ResGetGoogleSheetsSheet,
  ReqGetGoogleSheetsCell, ResGetGoogleSheetsCell,
  ReqGetGoogleAdminGroups, ResGetGoogleAdminGroups,
  ReqGetGoogleAdminOrgUnits, ResGetGoogleAdminOrgUnits,
  ReqGenerateAuthUrl, ResGenerateAuthUrl,
  ResGetToken, ReqGetToken, ReqSetToken, ResSetToken,
  ReqWatchChanges, ResWatchChanges, ReqInitCaches, ResInitCaches,
  ReqUpdateSettingStatus, ResUpdateSettingStatus,
} from '../types'

const REGION = 'us-west2'

export type CreatePortalLink = {
  returnUrl: string;
  locale?  : string; // Optional, defaults to "auto"
  configuration?: string; // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
}

export type PortalLink = {
  configuration: string;
  created  : number;
  customer : string;
  id       : string;
  livemode : boolean;
  locale   : string;
  object   : string;
  on_behalf_of: null;
  return_url: string;
  url      : string;
}

export const useFunctions = () => {
  const app = getApp()
  const functions = getFunctions(app, REGION)
  if (process.env.REACT_APP_FUNC_EMU) {
    if (process.env.REACT_APP_VERBOSE) console.debug('Using the local emulation of "Cloud Functions"')
    connectFunctionsEmulator(functions, 'localhost', 5001)
  }

  const generateAuthUrl = httpsCallable<ReqGenerateAuthUrl, ResGenerateAuthUrl>(functions, 'generateAuthUrl')
  const setToken = httpsCallable<ReqSetToken, ResSetToken>(functions, 'setToken')
  const getToken = httpsCallable<ReqGetToken, ResGetToken>(functions, 'getToken')
  const initCaches = httpsCallable<ReqInitCaches, ResInitCaches>(functions, 'initCaches')
  const syncChanges = httpsCallable<ReqSyncChanges, ResSyncChanges>(functions, 'syncChanges')
  const watchChanges = httpsCallable<ReqWatchChanges, ResWatchChanges>(functions, 'watchChanges')
  const updateSettingStatus = httpsCallable<ReqUpdateSettingStatus, ResUpdateSettingStatus>(functions, 'updateSettingStatus')

  const getGoogleDriveFiles = httpsCallable<ReqGetGoogleDriveFiles, ResGetGoogleDriveFiles>(functions, 'getGoogleDriveFiles')
  const getGoogleDriveFolders = httpsCallable<ReqGetGoogleDriveFiles, ResGetGoogleDriveFiles>(functions, 'getGoogleDriveFolders')

  const getGoogleCalendarCalendars = httpsCallable<ReqGetGoogleCalendarCalendars, ResGetGoogleCalendarCalendars>(functions, 'getGoogleCalendarCalendars')
  const getGoogleCalendarEvents = httpsCallable<ReqGetGoogleCalendarEvents, ResGetGoogleCalendarEvents>(functions, 'getGoogleCalendarEvents')

  const getGoogleSheetsSheet = httpsCallable<ReqGetGoogleSheetsSheet, ResGetGoogleSheetsSheet>(functions, 'getGoogleSheetsSheet')
  const getGoogleSheetsCell = httpsCallable<ReqGetGoogleSheetsCell, ResGetGoogleSheetsCell>(functions, 'getGoogleSheetsCell')

  const getGoogleAdminGroups = httpsCallable<ReqGetGoogleAdminGroups, ResGetGoogleAdminGroups>(functions, 'getGoogleAdminGroups')
  const getGoogleAdminOrgUnits = httpsCallable<ReqGetGoogleAdminOrgUnits, ResGetGoogleAdminOrgUnits>(functions, 'getGoogleAdminOrgUnits')

  const createPortalLink = httpsCallable<CreatePortalLink, PortalLink>(functions, 'ext-firestore-stripe-payments-createPortalLink')

  return {
    functions,
    generateAuthUrl, setToken, getToken,
    initCaches, syncChanges, watchChanges, updateSettingStatus,
    createPortalLink,
    // generateGoogleDriveAuthUrl, setGoogleDriveToken, getGoogleDriveToken,
    getGoogleDriveFiles, getGoogleDriveFolders,
    getGoogleCalendarCalendars, getGoogleCalendarEvents,
    getGoogleSheetsSheet, getGoogleSheetsCell,
    getGoogleAdminGroups, getGoogleAdminOrgUnits,
  }
}
