import {
  API_KEY,
  PROJECT_ID,
  AUTH_DOMAIN,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID,
  FIREBASE_API_KEY,
} from '../constants'

const constants : {[key:string]: string} = {
  API_KEY,
  PROJECT_ID,
  AUTH_DOMAIN,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID,
  FIREBASE_API_KEY,
}

export const Env = () => (
  <>
    <h2>constants</h2>
    <table>
      <tbody>
        { Object.keys(constants).sort().map(k => (
          <tr key={k}>
            <th style={{width:'21em', paddingRight: '1em', textAlign:'left'}}>{k}</th>
            <td>{constants[k]}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <h2>process.env</h2>
    <table>
      <tbody>
        { Object.keys(process.env).sort().map(k => (
          <tr key={k}>
            <th style={{width:'21em', paddingRight: '1em', textAlign:'left'}}>{k}</th>
            <td>{process.env[k]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
)

