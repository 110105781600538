import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SplashPage, NotFoundPage, HomePage, AccountPage, ActivityLogPage, ReceivePage } from './pages'
import { EnvPage } from './pages/Env'
import './App.scss'

import { initializeApp, FirebaseError } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import type { User } from 'firebase/auth'

import { UserContext, ProgressContext, ProgressStateItem } from './contexts'
import { Progress } from './components'
import { FirebaseConfig } from './constants'

initializeApp(FirebaseConfig)

const routes = [
  {path: '/', component: HomePage},
  {path: '/log', component: ActivityLogPage},
  {path: '/account', component: AccountPage},
  {path: '/env', component: EnvPage},
  {path: '/receive', component: ReceivePage},
  {path: '/*', component: NotFoundPage},
]

// const loginWithEmail = async (email:string, password:string) => {
//   const auth = getAuth()
//   try {
//     const credential = await signInWithEmailAndPassword(auth, email, password)
//     console.log(credential)
//   }
//   catch (err) {
//     console.error('err', err)
//   }
// }

const loginWithGoogle = async () => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  try {
    const result = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(result)
    console.log(result, credential)
  }
  catch (err) {
    if (err instanceof FirebaseError) {
      const credential = GoogleAuthProvider.credentialFromError(err)
      console.error('App.loginWithGoogle', err, credential)
    }
    else {
      console.error(err)
    }
  }
}

const App = () => {
  const auth = getAuth()
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    return onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user)
      }
      else {
        loginWithGoogle()
      }
    })
  }, [])

  // Progress

  const progressCounter = useRef(0)
  const [progressState, setProgressState] = useState<{[key:string]: ProgressStateItem}>({})

  const showProgress = (value?: string | ProgressStateItem) => {
    const id = `progress-${progressCounter.current++}`
    const progress = typeof value === 'string' ? {progress: value} : value || {progress: ''}

    setProgressState({
      ...progressState,
      [id]: progress,
    })
    return (update: string | ProgressStateItem | undefined) => {
      if (update) {
        const newProgress = typeof update === 'string' ? {progress: update} : update
        setProgressState({
          ...progressState,
          [id]: newProgress,
        })
      }
      else {
        const newState = {...progressState}
        delete newState[id]
        setProgressState(newState)
      }
    }
  }

  const clearProgress = () => setProgressState({})

  return (
    <ProgressContext.Provider value={{showProgress, clearProgress}}>
      <UserContext.Provider value={{ user, setUser }}>
        {
          user ? (
            <BrowserRouter>
              <Routes>
                { routes.map(({path, component: Component}, idx) => (
                  <Route key={`route-${idx}`} path={path} element={<Component/>} />
                )) }
              </Routes>
            </BrowserRouter>
          ) : ( <SplashPage /> )
        }
      </UserContext.Provider>
      <Progress state={progressState} />
    </ProgressContext.Provider>
  )
}

export default App
