import { User } from "firebase/auth"
import { createContext } from "react"

export type UserContextProps = {
  user   : User | null;
  setUser: (_: User) => void;
}

const InitialUserState = {
  user   : null,
  setUser: (_: User) => { /* stub */ },
}

export const UserContext = createContext<UserContextProps>(InitialUserState)
