import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

export const useFirestore = () => {
  const firestore = getFirestore()
  if (process.env.REACT_APP_STORE_EMU) {
    if (process.env.REACT_APP_VERBOSE) console.debug('Using the local emulation of "Firestore"')
    connectFirestoreEmulator(firestore, 'localhost', 8080)
  }
  return { firestore }
}
