import { useContext, useEffect, useRef, useState } from "react"
import { Switch } from "@headlessui/react"
import { ChevronDoubleDownIcon, PlusIcon } from "@heroicons/react/24/outline"
import { collection, doc, addDoc, getDoc, setDoc, writeBatch, getDocs, where, query } from "firebase/firestore"
import { classNames } from '../lib'
import { InitialSyncSetting, InitialSyncTarget, SyncSetting, syncSettingConverter } from "../types"
import { ProgressContext, UserContext } from "../contexts"
import { TargetView } from "./TargetEditor"
import { useFirestore, useFunctions } from "../composables"
import { DateTime } from "luxon"
import { SettingItemEditor } from "./SettingItemEditor"

type SettingEditorProps = {
  settingId : string | null | undefined; // string = edit setting, null = create setting, undefined = close dialog
  onClose: () => void;
}

export const SettingEditor = ({settingId, onClose}: SettingEditorProps) => {
  if (settingId === undefined) return null

  const { user } = useContext(UserContext)
  if (!user) return null

  const { firestore } = useFirestore()
  const { initCaches, watchChanges, updateSettingStatus } = useFunctions()

  const [settingType, setSettingType] = useState<"src" | "dst" | undefined>(undefined)
  const [syncSetting, setSyncSetting] = useState<SyncSetting>(InitialSyncSetting)

  const { showProgress } = useContext(ProgressContext)
  const removeProgress = useRef<(()=>void) | null>(null)

  useEffect(() => {
    if (settingId) {
      const docRef = doc(firestore, `/Users/${user.uid}/SyncSettings/${settingId}`).withConverter(syncSettingConverter)
      getDoc(docRef).then(snap => setSyncSetting(snap.data() || InitialSyncSetting))
    }
    else {
      setSyncSetting(InitialSyncSetting)
    }
  }, [])

  const save = async () => {
    if (!syncSetting.left.tokenId || !syncSetting.right.tokenId) {
      alert('Please select a trigger and an action.')
      return
    }

    removeProgress.current = showProgress('Saving...')
    try {
      if (syncSetting.id) { // Update the setting
        const docRef = doc(firestore, `/Users/${user.uid}/SyncSettings/${syncSetting.id}`).withConverter(syncSettingConverter)
        await setDoc(docRef, {...syncSetting, timestamp: DateTime.local()})
      }
      else { // Create a new setting
        const colRef = collection(firestore, `/Users/${user.uid}/SyncSettings`).withConverter(syncSettingConverter)
        const settingRef = await addDoc(colRef, {...syncSetting, timestamp: DateTime.local()})
        syncSetting.id = settingRef.id
      }
      {
        const res = await initCaches({settingId: syncSetting.id})
        if (!res.data || res.data.status != 200) {
          throw new Error(`Failed to initialize caches: ${JSON.stringify(res.data)}`)
        }
      }
      {
        const res = await watchChanges({settingId: syncSetting.id})
        if (!res.data || res.data.status != 200) {
          throw new Error(`Failed to watch changes: ${JSON.stringify(res.data)}`)
        }
      }
      {
        const res = await updateSettingStatus()
        if (!res.data || res.data.status != 200) {
          throw new Error(`Failed to count settings: ${JSON.stringify(res.data)}`)
        }
      }
      removeProgress.current?.()
      onClose()
    }
    catch (err) {
      console.error(err)
      removeProgress.current?.()
    }
  }

  const remove = async () => {
    if (!syncSetting.id) return
    removeProgress.current = showProgress('Deleting...')

    try {
      const batch = writeBatch(firestore)
      const snaps = await getDocs(query(
        collection(firestore, `/Channels`),
        where('settingId', '==', syncSetting.id)
      ))
      snaps.forEach(snap => batch.delete(snap.ref))
      batch.delete(doc(firestore, `/Users/${user.uid}/SyncSettings/${syncSetting.id}`))
      await batch.commit()
      {
        const res = await updateSettingStatus()
        if (!res.data || res.data.status != 200) {
          throw new Error(`Failed to count settings: ${JSON.stringify(res.data)}`)
        }
      }
      removeProgress.current?.()
      onClose()
    }
    catch (err) {
      console.error(err)
      removeProgress.current?.()
    }
  }

  return (
    <>
      <div className="msx-modal-backdrop justify-end p-0">
        <form className="msx-modal max-w-2xl h-full rounded-none" action="#">
          <div className="msx-modal-header">
            <div className="msx-modal-header-left">
              <div className="msx-modal-header-title">{syncSetting.id ? "Edit a sync setting" : "Add a new sync setting"}</div>
            </div>
            <div className="msx-modal-header-right">
              <button
                type="button"
                className="msx-close-btn"
                onClick={onClose}
              />
            </div>
          </div>
          <div className="msx-modal-content">
            <div className="flex flex-row grow-0 items-center gap-2 mb-6">
              <Switch
                checked={syncSetting.active}
                onChange={(active:boolean) => setSyncSetting({...syncSetting, active})}
                className={classNames(
                  "msx-toggle-switch-bar",
                  syncSetting.active ? "" : "msx-toggle-off"
                )}
              >
                <span className="msx-toggle-switch-btn" />
              </Switch>
              <div className="inline-block text-gray-600 font-base">
                This sync setting is currently{" "}
                 {syncSetting.active ? <span className="text-gray-900 font-extrabold">enabled</span> : <span className="font-bold">disabled</span>}.
              </div>
            </div>
            <div className="flex flex-col items-center gap-4">
              <div className="w-full space-y-2">
                <p className="text-slate-400 font-medium text-sm">If a change is detected in ...</p>
                  <a
                    onClick={() => setSettingType("src")}
                    className="group flex items-center justify-between text-base border border-slate-200 rounded-md px-6 pr-3 py-4 w-full bg-slate-50 hover:no-underline hover:border-slate-300 hover:bg-slate-100"
                  >
                  {syncSetting.left.tokenId !== null ? (
                    <>
                      <TargetView
                        type={'src'}
                        target={syncSetting.left}
                      />
                      <span className="block px-3 py-1 rounded-full font-medium text-blue-400 group-hover:text-blue-600 hover:text-blue-700 group-hover:bg-blue-100 hover:bg-blue-200">Edit</span>
                    </>
                  ) : (
                    <div className="flex items-center gap-2 text-blue-500 mx-auto py-2 group-hover:text-blue-600">
                      <PlusIcon className="w-5 h-5" />
                      <span className="font-medium">Add a trigger</span>
                    </div>
                  )}
                </a>
              </div>
              <div
                className={classNames(
                  syncSetting.left.tokenId && syncSetting.right.tokenId ? "text-slate-400" : "text-slate-300"
                )}
              >
                <ChevronDoubleDownIcon className="w-8 h-8" />
              </div>
              <div className="w-full space-y-2">
                <p className="text-slate-400 font-medium text-sm">Then, it'll be synchronized in ...</p>
                <a
                  onClick={() => setSettingType("dst")}
                  className="group flex items-center justify-between text-base border border-slate-200 rounded-md px-6 pr-3 py-4 w-full bg-slate-50 hover:no-underline hover:border-slate-300 hover:bg-slate-100"
                >
                  {syncSetting.right.tokenId !== null ? (
                    <>
                      <TargetView
                        type={'dst'}
                        target={syncSetting.right}
                      />
                      <span className="block px-3 py-1 rounded-full font-medium text-blue-400 group-hover:text-blue-600 hover:text-blue-700 group-hover:bg-blue-100 hover:bg-blue-200">Edit</span>
                    </>
                  ) : (
                    <div className="flex items-center gap-2 text-blue-500 mx-auto py-2 group-hover:text-blue-600">
                      <PlusIcon className="w-5 h-5" />
                      <span className="font-medium">Add an action</span>
                    </div>
                  )}
                </a>
              </div>
            </div>
          </div>
          {/* Action buttons */}
          <div className="msx-modal-footer">
            <div className="flex justify-end space-x-3">
              { syncSetting.id ? (
                <button
                  type="button"
                  className="msx-btn msx-btn-delete"
                  onClick={remove}
                >
                  Delete
                </button>
              ) : undefined }
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                className="msx-btn msx-btn-cancel"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="msx-btn msx-btn-primary"
                onClick={save}
              >
                { syncSetting.id ? 'Update' : 'Add' }
              </button>
            </div>
          </div>
        </form>
      </div>
      <SettingItemEditor
        type={settingType}
        target={settingType === 'dst' ? syncSetting.right : syncSetting.left}
        onChange={(target) => settingType && setSyncSetting({
          ...syncSetting,
          [settingType === 'dst' ? 'right' : 'left']: target,
        })}
        onClose={() => setSettingType(undefined)}
        onClear={() => {
          if (settingType === 'dst') {
            syncSetting.right = InitialSyncTarget
          }
          else {
            syncSetting.left = InitialSyncTarget
          }
          setSettingType(undefined)
        }}
        />
    </>
  )
}
