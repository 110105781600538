import React from 'react'
import { ProgressStateItem } from '../contexts'

type ProgressProps = {
  state: {[key:string]:ProgressStateItem}
}

export const Progress = ({state}: ProgressProps) => {
  if (Object.keys(state).length <= 0) return null

  const keys = Object.keys(state).sort((a, b) => a.localeCompare(b))
  const item = state[keys[0]]

  return (
    <div className="msx-modal-backdrop" style={{zIndex:1000,...item.style}}>
      <div className="msx-loader" />
      <span className="text-slate-300 animate-pulse">{item.progress}</span>
    </div>
  )
}
