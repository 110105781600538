import { useContext, Fragment } from 'react'
import { Link } from "react-router-dom"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { getAuth, signOut } from 'firebase/auth'

import { UserContext } from "../contexts"

type MenuItem = {
  label  : string;
  href   : string;
  action?: () => void;
}

const userNavigation : MenuItem[] = [
  { label: 'My account', href: '/account' },
  { label: 'Sign out', href: '#', action: () => {
    const auth = getAuth()
    signOut(auth)
  } },
]

export const UserMenu = () => {
  const { user } = useContext(UserContext)
  return (
    <Menu as="div" className="flex-shrink-0 relative ml-4">
      <div>
        <Menu.Button className="bg-white flex focus:outline-none">
          <span className="sr-only">Open user menu</span>
          <div className="flex items-center gap-2 px-3 py-2">
            <span className="text-sm text-gray-500">
              {user?.displayName}
            </span>
            <ChevronDownIcon className="h-4 text-gray-500" />
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="msx-dropdown-menu origin-top-right absolute right-0 mt-2 w-48">
          {userNavigation.map((item) => (
            <Menu.Item key={item.label}>
              <Link
                to={item.href}
                onClick={item.action}
                className="msx-dropdown-menu-item"
              >
                {item.label}
              </Link>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
