import { useContext} from "react"
import { SyncTarget } from "../types"
import { UserContext } from "../contexts"
import { SyncTargetEditor } from './TargetEditor'

type SettingItemEditorProps = {
  target    : SyncTarget | undefined;
  type      : 'src' | 'dst' | undefined;
  onChange  : (target: SyncTarget) => void;
  onClose   : () => void;
  onClear   : () => void;
}

export const SettingItemEditor = ({target, type, onChange, onClose, onClear}: SettingItemEditorProps) => {
  if (target === undefined) return null
  if (type === undefined) return null

  const { user } = useContext(UserContext)
  if (!user) return null

  return (
    <>
      <div className="msx-modal-backdrop">
        <form className="msx-modal msx-form-modal !max-w-2xl" action="#">
          <div className="msx-modal-header">
            <div className="msx-modal-header-left">
              <div className="msx-modal-header-title">Set up {type === "src" ? "a trigger" : "an action"} detail</div>
            </div>
            <div className="msx-modal-header-right">
              <button
                type="button"
                className="msx-close-btn"
                onClick={onClose}
              />
            </div>
          </div>
          <div className="msx-modal-content">
            <SyncTargetEditor
              type={type}
              target={target}
              onChange={onChange}
            />
          </div>
          {/* Action buttons */}
          <div className="msx-modal-footer">
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                className="msx-btn msx-btn-delete"
                onClick={onClear}
              >
                Clear
              </button>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                className="msx-btn msx-btn-primary"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
