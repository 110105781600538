import { doc, getDoc } from "firebase/firestore"
import { useFirestore, useFunctions } from "../composables"
import { User } from "firebase/auth"
import { tokenConverter } from "../types"

export const updateToken = async (user: User, tokenId: string) => {
  const { firestore } = useFirestore()
  const { generateAuthUrl } = useFunctions()

  const tokenSnap = await getDoc(doc(firestore, `/Users/${user.uid}/Tokens/${tokenId}`).withConverter(tokenConverter))
  const token = tokenSnap.data()
  if (!token) {
    alert('Failed to load token.')
    return
  }

  const res = await generateAuthUrl({
    providerId: token.providerId,
    settingId : null,
    loginHint : token.info.email,
  })

  if (res.data.status != 200) {
    console.error(res.data)
    return
  }
  if (!res.data.url) {
    console.error(res.data)
    return
  }
  window.open(res.data.url, 'membersync-auth', 'width=500,height=600')
}
