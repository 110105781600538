export const API_KEY             = process.env.REACT_APP_API_KEY as string
export const AUTH_DOMAIN         = process.env.REACT_APP_AUTH_DOMAIN as string
export const PROJECT_ID          = process.env.REACT_APP_PROJECT_ID as string
export const STORAGE_BUCKET      = process.env.REACT_APP_STORAGE_BUCKET as string
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID as string
export const APP_ID              = process.env.REACT_APP_APP_ID as string
export const MEASUREMENT_ID      = process.env.REACT_APP_MESUREMENT_ID as string

export const FIREBASE_API_KEY    = process.env.REACT_APP_FIREBASE_API_KEY as string

export const FirebaseConfig = {
  apiKey           : FIREBASE_API_KEY || API_KEY,
  authDomain       : AUTH_DOMAIN,
  projectId        : PROJECT_ID,
  storageBucket    : STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId            : APP_ID,
  measurementId    : MEASUREMENT_ID,
}
